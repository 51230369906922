
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private http:HttpClient,
    private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging?.messages.subscribe(
      (_messaging: any) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(usuario, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[usuario.uuid] = token;
        this.http.post(environment.apiUrl+'/usuario/'+usuario.idUsuario+'/token', { idUsuario: usuario.idUsuario, tokenPush: token });
        this.angularFireDB.object('fcmTokens/').update(data)
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(usuario) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        localStorage.setItem('permissaoDeNotificacaoConcedida', '1');
        localStorage.setItem('tokenDeNotificacao', token);
        this.updateToken(usuario, token);
      },
      (err) => {
        if (environment.production) console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        //  console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }
}