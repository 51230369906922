<div class="cardmenu-menu-footer fixed-bottom text-center">
  <div class="row m-0">
    <a
      (click)="[navigate('/inicio'), (currentMenu = 'inicio')]"
      [ngClass]="{ 'selected fw-bold': currentMenu == 'inicio' }"
      class="text-muted col ion-activatable ripple-parent text-decoration-none p-2"
    >
      <p class="h5 m-0">
        <i class="fas fa-home"></i>
      </p>
      {{ 'Início' | translate }}
      <ion-ripple-effect hidden></ion-ripple-effect>
    </a>

    <a
      (click)="[navigate('/cardapio'), (currentMenu = 'cardapio')]"
      [ngClass]="{ 'selected fw-bold': currentMenu == 'cardapio' }"
      class="text-muted col ion-activatable ripple-parent text-decoration-none p-2"
    >
      <p class="h5 m-0">
        <i class="fas fa-clipboard-list"></i>
      </p>
      {{ 'Cardápio' | translate }}
      <ion-ripple-effect hidden></ion-ripple-effect>
    </a>

    <a
      hidden
      (click)="[navigate('/favoritos'), (currentMenu = 'favoritos')]"
      [ngClass]="{ 'selected fw-bold': currentMenu == 'favoritos' }"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="selected fw-bold"
      class="text-muted col ion-activatable ripple-parent text-decoration-none p-2"
    >
      <p class="h5 m-0">
        <i *ngIf="currentMenu !== 'favoritos'" class="fal fa-heart"></i>
        <i *ngIf="currentMenu == 'favoritos'" class="fas fa-heart"></i>
      </p>
      {{ 'Favoritos' | translate }}
      <ion-ripple-effect hidden></ion-ripple-effect>
    </a>
    <a
      hidden
      (click)="[navigate('/cardapio'), (currentMenu = 'cardapio')]"
      [ngClass]="{ 'selected fw-bold': currentMenu == 'cardapio' }"
      class="text-muted d-flex justify-content-center col-2 text-decoration-none p-2"
    >
      <div class="botao-massa ion-activatable">
        <p class="h5 m-0">
          <i class="fas text-white fa-clipboard-list" style="color: white !important"></i>
          <img
            [src]="(estabelecimentoAtual | async)?.image"
            hidden
            style="height: 1.2em; max-width: 1.35em; margin: 3px 0; border-radius: 6px"
            *ngIf="(estabelecimentoAtual | async)?.image"
            alt=""
          />
        </p>
        <span style="color: white !important">{{ 'Cardápio' | translate }}</span>
        <ion-ripple-effect hidden></ion-ripple-effect>

        <small hidden *ngIf="(estabelecimentoAtual | async)?.fantasia" style="color: white !important; line-height: 6px">{{
          (estabelecimentoAtual | async)?.fantasia
        }}</small>
      </div>
    </a>
    <a
      *ngIf="atendimento?.tipoAtendimentoEnum == 'COMANDA'"
      (click)="[navigate('/comanda'), (currentMenu = 'comanda')]"
      routerLinkActive="selected fw-bold"
      [ngClass]="{ 'selected fw-bold': currentMenu == 'comanda' }"
      class="text-muted col ion-activatable ripple-parent col text-decoration-none p-2"
    >
      <p class="h5 m-0">
        <i class="fas fa-list-alt"></i>
      </p>
      Comanda
      <ion-ripple-effect hidden></ion-ripple-effect>
    </a>
    <a
      *ngIf="atendimento?.tipoAtendimentoEnum != 'COMANDA'"
      (click)="[navigate('/pedidos'), (currentMenu = 'pedidos')]"
      routerLinkActive="selected fw-bold"
      [ngClass]="{ 'selected fw-bold': currentMenu == 'pedidos' }"
      class="text-muted col ion-activatable ripple-parent col text-decoration-none p-2"
    >
      <p class="h5 m-0">
        <i class="fas fa-list-alt"></i>
      </p>
      Pedidos
      <ion-ripple-effect hidden></ion-ripple-effect>
    </a>
    <a
      (click)="[navigate('/conta'), (currentMenu = 'conta')]"
      [ngClass]="{ 'selected fw-bold': currentMenu == 'conta' }"
      class="text-muted ion-activatable ripple-parent col text-decoration-none p-2"
    >
      <p class="h4 m-0">
        <i class="fas fa-user-circle"></i>
      </p>
      {{ 'Conta' | translate }}
      <ion-ripple-effect hidden></ion-ripple-effect>
    </a>
  </div>
</div>
