<div class="swipe-handler mt-2"></div>
<ion-header>
  <h6 class="px-4 mn-0 pt-2 text-dark text-end" (click)="fecharModal()"><i class="fal fa-2x fa-times"></i> &nbsp;</h6>
</ion-header>
<ion-content>
  <div class="px-3 pb-3" style="overflow: scroll">
    <section class="px-3">
      <ion-radio-group [value]="enderecoSelecionado" mode="md">
  

        <div class="row mb-3 mt-1">
          <div class="col">
            <h5 class="fw-bold mt-2 mb-0">Selecione</h5>
          </div>
          <div class="col-auto d-flex align-items-center border rounded border-danger">
            <span class="text-danger" (click)="presentModal()">+ Novo endereço</span>
          </div>
        </div>
        <div *ngIf="!((enderecos | async)?.length > 0)">
          <div *ngIf="!loading" class="placeholder placeholder-sm px-5">
            <br />
            <ion-img [src]="'/assets/img/illustrations/empty.svg'" style="height: 8em"></ion-img>
            <br />
            <b class="mb-2">Você ainda não adicionou nenhum endereço.</b>
            <br />
            <br />
            <button (click)="presentModal()" class="btn my-3 btn-sm py-2 btn-warning text-center">
              Adicionar endereço &nbsp;
              <i class="fal fa-long-arrow-right"></i>
            </button>
          </div>
        </div>
        <div
          class="rounded mt-2 p-3 border w-100"
          (click)="selecionarEndereco(endereco)"
          *ngFor="let endereco of enderecos | async"
          [ngClass]="{ 'text-white bg-dark': enderecoSelecionado?.idEndereco == endereco?.idEndereco }"
        >
          <div class="row">
            <div class="col">
              <p class="fw-bold">{{ endereco.nomeEndereco }}</p>
              <span>{{ endereco.logradouro }}, {{ endereco.bairro }}, {{ endereco.numeroLogradouro }}{{ endereco.valorFrete }}</span>
            </div>
            <div class="col-auto d-flex align-items-center">
              <span class="text-success">{{ endereco.preco | currency: 'BRL':'symbol-narrow':'1.2-2' }}</span>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
      </ion-radio-group>
      <ion-button hidden expand="block" class="mt-4" color="medium" fill="outline">
        <i class="fas fa-plus"></i>
        Cadastrar endereço
      </ion-button>
      <p *ngIf="!enderecoSelecionado">
        <br />
        <br />
        <br />
        <br />
      </p>
      <div class="w-100 bg-white px-3 pt-3 animate__animated animate__fadeIn" style="height: 7em" *ngIf="enderecoSelecionado?.idEndereco" #botaoSelecionar>
        <ion-button expand="block" (click)="fecharModal()" color="danger"> Selecionar </ion-button>
      </div>
    </section>
  </div>
  <br />
  <br />
  <br />
</ion-content>
