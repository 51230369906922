import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { IonSearchbar, LoadingController, ModalController } from '@ionic/angular';
import { fromEvent } from 'rxjs';
import { Endereco } from 'src/app/_models/Endereco';
import { EnderecoService } from 'src/app/_services/endereco.service';
import { SharedService } from 'src/app/_services/shared.service';

declare var google: any;
@Component({
  selector: 'app-add-enderecos',
  templateUrl: './add-enderecos.page.html',
  styleUrls: ['./add-enderecos.page.scss'],
})
export class AddEnderecosPage implements OnInit {
  @ViewChild('map', { static: false }) mapElement: ElementRef;
  map: any;
  endereco: Endereco = new Endereco();
  lat: string;
  long: string;
  enderecoFormatado;
  autocomplete: { input: string; };
  autocompleteItems: any[];
  GoogleAutocomplete: any;
  exibir;
  @ViewChild('autofocus', { static: false }) searchbar: IonSearchbar;
  backbuttonSubscription: any;


  constructor(
    private geolocation: Geolocation,
    private ss: SharedService,
    public zone: NgZone,
    public modalCtrl: ModalController, private loadingController: LoadingController, private es: EnderecoService, private http: HttpClient
  ) {

    this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
    this.autocomplete = { input: '' };
    this.autocompleteItems = [];
  }

  ngOnInit() {
    this.loadMap();
    const event = fromEvent(document, 'backbutton');
    this.backbuttonSubscription = event.subscribe(async () => {
      const modal = await this.modalCtrl.getTop();
      if (!this.exibir)
        if (modal)
          modal.dismiss();
      if (this.exibir == 'mapa')
        this.exibir = null;
      if (this.exibir == 'list')
        this.exibir = null;
      if (this.exibir == 'confirmar')
        this.exibir = 'mapa';

    });
  }

  ngOnDestroy() {
    this.backbuttonSubscription.unsubscribe();
  }


  loadMap() {
    this.enderecoFormatado = null;
    this.geolocation.getCurrentPosition().then((resp) => {
      this.updateMapa(resp.coords.latitude, resp.coords.longitude);
    }).catch((error) => {
      console.log('Error getting location', error);
    });
  }

  UpdateSearchResults() {
    if (this.autocomplete.input == '') {
      this.autocompleteItems = [];
      return;
    }
    this.GoogleAutocomplete.getPlacePredictions({ input: this.autocomplete.input, componentRestrictions: { country: 'br' } },
      (predictions, status) => {

        this.autocompleteItems = [];
        this.zone.run(() => {
          predictions?.forEach((prediction) => {
            this.autocompleteItems.push(prediction);
          });
        });
      });
  }

  SelectSearchResult(item) {
    console.log((item.description))
    const url = 'https://maps.google.com/maps/api/geocode/json?address=' + item.description + '&key=AIzaSyB6QgBvFSz8Zy_-4YvsGsOpA3IhnVJMZEA';
    fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        let actual = responseJson.results[0].geometry.location;
        this.updateMapa(actual.lat, actual.lng);
      })
    this.exibir = 'mapa';
    this.autocomplete.input = item.description;
    this.autocompleteItems = [];
  }

  updateMapa(lat, lng) {
    const latLng = new google.maps.LatLng(lat, lng);
    let mapOptions = {
      center: latLng,
      zoom: 17,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      fullscreenControl: false
    }
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
    this.lat = this.map.center.lat()
    this.long = this.map.center.lng()
    this.retornarEndereco();
    this.map.addListener('idle', () => {
      console.log('center changed')
      this.lat = this.map.center.lat()
      this.long = this.map.center.lng()
      this.retornarEndereco();
    });
  }

  ClearAutocomplete() {
    this.autocompleteItems = []
    this.autocomplete.input = ''
  }

  mudarTela(tela) {
    setTimeout(() => {
      this.exibir = tela;
    }, 100);
    if (!tela) {
      this.loadMap();

      if (tela == 'list')
        setTimeout(() => this.searchbar.setFocus(), 500);


    }
  }


  retornarEndereco() {
    const url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + this.lat + ',' + this.long + '&key=AIzaSyB6QgBvFSz8Zy_-4YvsGsOpA3IhnVJMZEA'
    fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        let actual = responseJson.results[1]
        this.enderecoFormatado = responseJson.results[1].formatted_address;
        this.endereco.latitude = this.lat;
        this.endereco.longitude = this.long;
        this.endereco.numeroLogradouro = actual.address_components.find(a => a.types.includes("street_number"))?.long_name || ``;
        this.endereco.logradouro = actual.address_components.find(a => a.types.includes("route"))?.long_name || ``;
        this.endereco.bairro = actual.address_components.find(a => a.types.includes("sublocality_level_1"))?.long_name || ``;
        this.endereco.localidade = actual.address_components.find(a => a.types.includes("administrative_area_level_2"))?.long_name || ``;
        this.endereco.siglaEstado = actual.address_components.find(a => a.types.includes("administrative_area_level_1"))?.short_name || ``;
        this.endereco.uf = actual.address_components.find(a => a.types.includes("administrative_area_level_1"))?.short_name || ``;
        this.endereco.cep = actual.address_components.find(a => a.types.includes("postal_code"))?.long_name || ``;
        console.log(this.endereco);
      })
  }


  async salvarEndereco() {
    let loader = this.loadingController.create();
    (await loader).present();

    this.endereco.uf = this.endereco.uf || this.endereco.siglaEstado;
    this.es.salvarEndereco(this.endereco)
      .subscribe(a => {
        this.ss.show('Endereço cadastrado com sucesso','success');
        this.modalCtrl.dismiss(a);
        this.loadingController.dismiss();
      }, error => {
        this.ss.show(error.error.message, 'danger');
        this.loadingController.dismiss();
      });
  }

  fecharModal(){
    this.modalCtrl.dismiss();
  }

}