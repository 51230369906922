<style>
  p {
    color: black;
  }

  .numero-cartao {
    font-family: 'Credit Card', sans-serif;
  }

  img {
    margin-top: -4px;
  }

  ion-item ion-label {
    padding: 0.41em 0;
  }

  ion-item {
    --border-color: #ededed;
  }
</style>

<div class="swipe-handler"></div>

<div class="pt-2">
  <div class="row">
    <div class="col-auto px-4">
      <ion-segment class="mb-4" mode="md" value="friends">
        <ion-segment-button value="friends">
          <ion-label>Pagamento:</ion-label>
        </ion-segment-button>
        <ion-segment-button hidden value="enemies">
          <ion-label>Pagar na entrega</ion-label>
        </ion-segment-button>
      </ion-segment>
    </div>
    <div class="col-12">
      <div class="" style="min-height: 10em">
        <loading class="mt-5" *ngIf="loading"></loading>

        <div *ngIf="!loading" class="p-4" style=" max-height: 550px; overflow-y: scroll;">
          <section class="mt-1" *ngIf="outrosMetodos.length">
            <div *ngIf="pagamentoOnline" class="row">
              <div class="col">
                <h5 hidden>Outras opções</h5>
              </div>
            </div>
            <ion-list>
              <div
                *ngFor="let metodo of outrosMetodos"
                (click)="selecionarMetodo(metodo)"
                style="background-color: #f5f3f2; border-radius: 10px"
                class="mb-3 p-2 d-flex justify-content-between"
              >
                <div class="d-flex py-2 flex-row" style="width: 80%; font-size: 1.1em">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <img src="{{ metodo.icon }}" style="height: 2em" alt="" />
                  <p class="my-0" style="text-transform: capitalize">
                    &nbsp;&nbsp;&nbsp;&nbsp;{{ metodo?.nome }}
                    <ion-icon *ngIf="metodo?.cartaoPrincipal" name="star-outline"></ion-icon>
                  </p>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <ion-avatar
                  *ngIf="metodo.selecionado"
                  slot="end"
                  style="padding: 7px; font-size: 17px; height: 1.5em; width: 1.5em"
                  class="bg-success text-white d-flex justify-content-center align-items-center"
                >
                  <i class="fal fa-check"></i>
                </ion-avatar>
              </div>
            </ion-list>
          </section>

          <section *ngIf="pagamentoOnline">
            <ion-list>
              <div
                *ngFor="let metodo of meusCartoes"
                (click)="selecionarMetodo(metodo)"
                style="background-color: #fdf5f2; border-radius: 10px;"
                class="mb-3 py-2 px-3 d-flex justify-content-between"
              >
                <div class="d-flex flex-row g-0 py-2" style="width: 80%; font-size: 1.1em">
                  <div class="col-2 d-flex justify-content-center">
                    <img src="{{ metodo.icon }}" style="height: 2em" alt="" />
                  </div>
                  <div class="col-4">
                    <p class="my-0" style="text-transform: capitalize; display: flex; align-items: center;">
                      &nbsp;&nbsp;{{ metodo?.nome }}
                      <ion-icon *ngIf="metodo?.cartaoPrincipal" name="star-outline" class="mx-1"></ion-icon>
                    </p>
                  </div>
                  <div class="col-4">
                    <span class="numero-cartao fw-bold m-0" style="color: #54a2fb"><span style="font-size: 16px">••••</span> {{ metodo?.numeroCartao }}</span>
                  </div>
                </div>
                <ion-avatar
                  *ngIf="metodo.selecionado"
                  slot="end"
                  style="padding: 7px; font-size: 17px; height: 1.5em; width: 1.5em"
                  class="bg-success text-white d-flex justify-content-center align-items-center"
                >
                  <i class="fal fa-check"></i>
                </ion-avatar>
              </div>
            </ion-list>
          </section>

          <div class="py-3 mt-1 mx-0">
            <ion-button (click)="abrirNovoCartao()" expand="block" color="warning" *ngIf="possuiOpcoesCartao">
              <ion-spinner color="light" *ngIf="loading" name="crescent"></ion-spinner>
              <span *ngIf="!loading"> <i class="fas fa-credit-card"></i> Criar novo cartão </span>
              &nbsp;<ion-icon name="add-outline"></ion-icon>
            </ion-button>
          </div>
          <ion-button color="light" (click)="fecharModal()" expand="block">
            <span> &nbsp; Fechar </span>
          </ion-button>
        </div>
      </div>
    </div>
  </div>
</div>
