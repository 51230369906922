import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SharedService } from 'src/app/_services/shared.service';
import { PhoneauthPage } from '../phoneauth/phoneauth.page';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {
  sb: any;
  defaultLanguage;
  entering;
  returnUrl;
  loading: boolean = false;
  token: any;
  user: Object;
  inAppBrowser;
  constructor(private router: Router,
    private translate: TranslateService,
    private route: ActivatedRoute,
    public modalController: ModalController,
    public authService: AuthenticationService,
    public viewCtrl: ModalController,
    private ss: SharedService,
    private http: HttpClient,
    private lc: LoadingController,
    private nc: NavController,
  ) {
    this.defaultLanguage = localStorage.getItem('defaultLanguage') || 'pt';
  }

  ngOnInit() {
    this.showLoader();
    setTimeout(() => {
      this.closeLoader();
    }, 800);
    this.authService.getRedirectResult().then((result) => {
      if (result.user) {
        this.showLoader();
        this.authService.thenPromiseSignInWithProvider(result);
      }
    });

    this.inAppBrowser = (navigator.userAgent.includes("Instagram") || navigator.userAgent.includes("FBAN"))

    this.returnUrl = (this.route.snapshot.queryParams['returnUrl']) ? this.route.snapshot.queryParams['returnUrl'] : null;

    this.loading = false;
    this.sb = this.authService.$authState.subscribe(res => {
      setTimeout(() => {
        this.loading = false;
      }, 400);
      if (res) {
        this.closeLoader()
        this.modalController.dismiss(res);
      }
    });
  }



  close() {
    this.modalController.dismiss();
  }

  async showLoader() {
    const loading = await this.lc.create({ message: '', duration: 25000 });
    loading.present();
    const { role, data } = await loading.onDidDismiss();
  }

  closeLoader() {
    this.lc.dismiss();
  }




  toggleLang(lang) {
    localStorage.setItem('defaultLanguage', lang);
    this.defaultLanguage = lang;
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: PhoneauthPage,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }
}
