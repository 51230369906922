import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'loading',
  template: `
  <div class="row d-flex align-items-center w-100 mx-0 justify-content-center mt-4 d-flex" >
  <div class="col-auto" style="height: 4em;">
    <img src="assets/loader.svg" alt="">
  </div>
</div>
`,
})
export class LoadingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


}
