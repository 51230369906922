<br />
<div class="fixed-top px-5 pt-3 pb-2">
  <div class="row">
    <div class="col">
      <logo [size]="'s'"></logo>
    </div>
  </div>
</div>
<br />
<br />
<br />
<div class="px-5" style="overflow-y: scroll;
height: 100%;
overflow-x: hidden;">
  <section id="phone" class="animate__fadeIn animate__animated" *ngIf="!codeSent">
    <div class="row">
      <div class="col x">
        <ion-select
          #selectCountry
          style="display: none"
          interface="alert"
          mode="md"
          [(ngModel)]="countrySelected"
          placeholder="Seu país"
          okText="Ok"
          cancelText="Cancelar">
          <ion-select-option *ngFor="let country of countries|async" [value]="country"> +{{country?.callingCode}}
            {{country?.name}} </ion-select-option>
        </ion-select>

        <p class="text-center" style="font-size: 1.6em" class="fs-16px">{{'continuar com o telefone'|translate}}</p>
        <div class="d-flex flex-column justify-content-center align-items-center pt-4 pb-4">
          <img style="height: 120px" src="https://notarypublicglobal.com/img/core-img/otp.svg" alt="" />
        </div>
        <p class="text-center fw-bold" style="font-size: 19px"></p>
        <br />
        <ion-row class="border-bottom w-100">
          <ion-col class="p-0" style="display: flex; flex-direction: column; justify-content: end; align-items: center"
            size="2" (click)="openSelectCountry()">
            <p style="    font-size: 21px;margin-bottom: 2px;" class="mx-1">+{{countrySelected?.callingCode}}</p>
          </ion-col>
          <ion-col class="p-0" size="10" offset-xl="2">
            <ion-item style="--padding-start: 0" class="ion-no-padding" lines="none">

              <ion-input
                type="tel"
                style="--padding-bottom: 0; align-items: end"
                autocapitalize="words"
                class="uppercase"
                maxlength="15"
                placeholder="Informe o telefone"
                mask="(**) *****-****"
                [(ngModel)]="phoneNumber"
                clearInput></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>

        <div class="alert alert-sm alert-danger" style="width: fit-content" *ngIf="msg?.error" role="alert">
          {{msg?.error}}</div>

        <div hidden class="row border-bottom">
          <div class="col-auto">
            <span>+{{countrySelected?.callingCode}} </span>
          </div>
          <div class="col">
            <input placeholder="Insira seu telefone" style="letter-spacing: 1px" minlength="11"
              [(ngModel)]="phoneNumber" maxlength="11" />
          </div>
        </div>

        <small class="text-muted mb-2 py-2">Enviaremos um código por SMS para esse número</small>

        <div hidden class="fixed-botto pt-5 px-0 pb-5 w-100">
          <ion-button expand="block" [disabled]="!(phoneNumber?.length>14)||loading" color="dark"
            (click)="sendLoginCode()">
            <ion-spinner color="light" *ngIf="loading" name="crescent"></ion-spinner>
            <span *ngIf="!loading"> {{'Enviar código'|translate}} </span>
          </ion-button>
        </div>
         <div  class="fixed-botto pt-5 px-0 pb-5 w-100">
          <ion-button expand="block" [disabled]="!(phoneNumber?.length>14)||loading" color="dark"
            (click)="loginAnonimo()">
            <ion-spinner color="light" *ngIf="loading" name="crescent"></ion-spinner>
            <span *ngIf="!loading"> Logar </span>
          </ion-button>
        </div>
      </div>
    </div>
  </section>


  <section id="code" *ngIf="codeSent" class="animate__fadeIn animate__animated">
    <div class="row">
      <div class="col px-4">
        <span style="font-size: 1.6em" class="fs-16px">{{'Insira o código de autenticação'|translate}}:
        </span>
        <div class="my-5">
          <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:6, allowNumbersOnly: true}">
          </ng-otp-input>
        </div>
        <small class="text-muted">O código de verificação foi enviado para seu telefone: <b> {{phoneNumber}}</b></small>
        <br>
        <br>

        <div class="w-100 text-center d-flex justify-content-center">
          <div class="alert animate__fadeIn animate__animated border-0 alert-sm mb-n3 alert-danger"
            style="width: fit-content" *ngIf="msg?.error" role="alert">
            {{msg?.error}}
          </div>
        </div>
      </div>
    </div>
    <div class="pt-5 px-0 pb-5 w-100">
      <ion-button expand="block" [disabled]="!(verificationCode?.length>5)||loading" color="dark"
        (click)="verifyLoginCode()">
        <ion-spinner color="light " *ngIf="loading" name="crescent"></ion-spinner>
        <span *ngIf="!loading"> {{'Validar código'|translate}} </span>
      </ion-button>
    </div>
    <div class="d-flex flex-column align-items-center">
      <a (click)="reenviar()" class="text-center">Não recebeu um código? <span *ngIf="timer>0">reenviar em {{timer}}s</span><b
          class="text-primary"
          *ngIf="timer==0">Reenviar</b>
      </a>

      <a class="btn my-2 btn-lg btn-block rounded btn-link text-center text-success"
        (click)="alterarNumero()">
        <i class="fas fa-phone"></i> &nbsp;Tentar outro número
      </a>
  
      <a *ngIf="timer<16" class="btn my-2 btn-lg btn-block rounded btn-link text-center"
        (click)="loginAnonimo()">
        <i class="fal fa-user"></i> &nbsp;Entrar como convidado
      </a>
    </div>
  </section>

  <div class="row justify-content-center" hidden>
    <div class="col-11">
      <div id="recaptcha-container"></div>

      <div class="modal-content">
        <div class="modal-header px-4">
          <h5 class="modal-title" id="edit-profile">Verificação</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body px-4">
          <div *ngIf="!userVerified">
            <div class="form-row" *ngIf="!windowRef.confirmationResult">
              <div class="form-group col-md-12">
                <h6>Para fazer pedidos, vamos confirmar seu telefone. :)</h6>
                <br />
                <br />
                <label>Insira seu número de celular</label>
                <input type="text" class="form-control" [(ngModel)]="phoneNumber" (keyup.enter)="sendLoginCode()"
                  placeholder="Insira seu telefone" />
              </div>
            </div>
            <div class="form-row" *ngIf="windowRef.confirmationResult">
              <div class="form-group col-md-12">
                <h6>Pronto, agora te enviamos um código</h6>
                <br />
                <br />
                <label>Insira o código que foi enviado.</label>
                <input type="text" class="form-control" [(ngModel)]="verificationCode" (keyup.enter)="verifyLoginCode()"
                  placeholder="Ex.: 00000" />
              </div>
              <br />
              <div class="w-100 text-center d-flex justify-content-center">
                <div class="alert alert-sm alert-danger" style="width: fit-content" *ngIf="msg?.error" role="alert">
                  {{msg?.error}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center px-4">
          <button type="button" *ngIf="!windowRef.confirmationResult"
            class="btn d-flex btn-sm text-center justify-content-center btn-primary" (click)="sendLoginCode()">
            Enviar código para o celular
          </button>
          <button type="button" *ngIf="windowRef.confirmationResult"
            class="btn d-flex btn-sm text-center justify-content-center btn-success" (click)="verifyLoginCode()">
            Verificar código
          </button>
        </div>
      </div>
    </div>
  </div>
</div>