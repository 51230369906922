import { Component, Input, OnInit } from '@angular/core';
import { ActionSheetController, LoadingController, ModalController } from '@ionic/angular';
import { ManterCartaoPage } from 'src/app/conta/meus-cartoes/manter-cartao/manter-cartao.page';
import { tipoAtendimentoEnum } from 'src/app/_models/atendimento';
import { Estabelecimento } from 'src/app/_models/estabelecimento';
import { PagamentoService } from 'src/app/_services/pagamento.service';
import { PedidoService } from 'src/app/_services/pedido.service';

@Component({
  selector: 'app-modal-selecionar-pagamentos',
  templateUrl: './modal-selecionar-pagamentos.component.html',
})
export class ModalSelecionarPagamentosComponent implements OnInit {
  outrosMetodos: any = [];
  meusCartoes = [];
  metodoSelecionado;
  loading;
  @Input() tipoAtendimento;
  pix;
  pagamentoOnline;
  qtdeCartoes;
  estabelecimento: Estabelecimento = JSON.parse(
    localStorage.getItem('estabelecimento'),
  );
  metodoPagamentoAceitos: any;
  iconePagamentoEntrega: any = '//cdn1.iconfinder.com/data/icons/logistics-transportation-vehicles/202/logistic-shipping-vehicles-002-512.png';
  possuiOpcoesCartao:boolean = false;
  constructor(private modalController: ModalController,
    private ps: PedidoService,
    private actionSheetController: ActionSheetController,
    private loadingController: LoadingController, private mp: PagamentoService) {

  }

  ngOnInit() {
    console.log(this.tipoAtendimento)

    this.meusCartoes = [];
    this.outrosMetodos = [];
    this.loading = true;

    this.ps
      .getDadosCheckout('METODO_PAGAMENTO_ACEITOS', {
        idEstabelecimento: this.estabelecimento.idEstabelecimento,
      })
      .subscribe((r) => {
        this.metodoPagamentoAceitos = r.listMetodoPagamentoEntity;
        this.pix = this.metodoPagamentoAceitos.find(a => a?.metodoPagamento?.toUpperCase() == 'PIX');
        if (this.pix)
          this.addMetodo({ ...this.pix, pagamentoOnline: true, nome: 'Pix', icon: "//logospng.org/download/pix/logo-pix-icone-512.png" })
        this.pagamentoOnline = this.metodoPagamentoAceitos.find(a => a?.metodoPagamento?.toUpperCase() == 'PAGAMENTO ONLINE');

        if (this.pagamentoOnline)
          this.carregarCartoes();
        else
          setTimeout(() => {
            this.loading = false;
          }, 300);
          
        const opcoesCartao = [];
        this.metodoPagamentoAceitos
          .map((a) => {
            const index = a.metodoPagamento.indexOf(' - ');
            if (index > 0) {
              a.tipo = a.metodoPagamento.slice(0, index);
              a.bandeira = a.metodoPagamento.slice(index + 3, a.metodoPagamento.length);
            }
            return a;
          })
          .filter((a) => a.tipo)
          .map((a) => {
            // verificar se já existe crédito ou débito
            if (!opcoesCartao.map((b) => b.metodoPagamento).includes(a.tipo)) {
              opcoesCartao.push({
                metodoPagamento: a.tipo,
                opcoes: this.metodoPagamentoAceitos.filter((b) => b.tipo == a.tipo),
              });
            }
          });
          if(opcoesCartao?.length>0){
            this.possuiOpcoesCartao = true;
          }
        this.metodoPagamentoAceitos = [
          ...opcoesCartao,
          ...this.metodoPagamentoAceitos.filter((a) => a.metodoPagamento.indexOf('-') == -1),
        ];


        if (this.tipoAtendimento == tipoAtendimentoEnum.DELIVERY)
          this.addMetodo({ nome: 'Pagamento na entrega', pagamentoEntrega: true, icon: this.iconePagamentoEntrega })
      });
  }

  carregarCartoes() {
    this.mp.getCartoes().subscribe(r => {
      setTimeout(() => {
        this.loading = false;
      }, 300);
      r.map(a => {
        a.tipoCartaoEnum = a?.tipoCartaoEnum?.toLowerCase();
        switch (a.bandeiraCartaoEnum) {
          case 'MASTERCARD':
            a.icon = '/assets/icons/credit-card/mastercard.png';
            break;
          case 'VISA':
            a.icon = '/assets/icons/credit-card/visa.svg';
            break;
          default:
            a.icon = '/assets/icons/credit-card/credit-cards.svg';
            break;
        };
        a.idMetodoPagamento = this.pagamentoOnline?.idMetodoPagamento;
        a.nome = a.tipoCartaoEnum;
        a.pagamentoOnline = true;
        a.bandeiraCartaoEnum = a?.bandeiraCartaoEnum?.toLowerCase();
        return a;
      });
      this.meusCartoes = r?.reverse();
      this.qtdeCartoes = r.length;

    });
  }

  addMetodo(metodo) {
    this.outrosMetodos.push(metodo)
  }

  async promptMetodoPagamentoEntrega() {
    const buttons = [];
    this.metodoPagamentoAceitos.filter(a => (a?.metodoPagamento?.toUpperCase() != 'PIX' && a?.metodoPagamento?.toUpperCase() != 'PAGAMENTO ONLINE')).map((r) => {
      buttons.push({
        text: r.metodoPagamento,
        handler: () => {
          if (!r.idMetodoPagamento) {
            this.promptOpcoesCartao(r);
          } else {
            r.nome = 'Na entrega: ' + r.metodoPagamento;
            r.icon = this.iconePagamentoEntrega;
            r.pagamentoOnline = false;
            this.metodoSelecionado = r;
            this.selecionarMetodo(r);
          }
        },
      });
    });
    const actionSheet = await this.actionSheetController.create({
      header: 'Escolha a forma de pagamento',
      buttons: [
        ...buttons,
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            //    this.atendimento.metodoPagamento = null;
          },
        },
      ],
    });
    await actionSheet.present();
  }

  async promptOpcoesCartao(metodo) {
    const buttons = [];
    if (!metodo.opcoes) return;
    metodo.opcoes.map((r) => {
      buttons.push({
        text: r.bandeira,
        handler: () => {
          r.nome = 'Na entrega: ' + r.metodoPagamento;
          r.icon = this.iconePagamentoEntrega;
          this.metodoSelecionado = r;
          this.selecionarMetodo(r);
        },
      });
    });
    const actionSheet = await this.actionSheetController.create({
      header: metodo.metodoPagamento + ': Selecione a bandeira',
      buttons: [
        ...buttons,
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            //this.atendimento.metodoPagamento = null;
          },
        },
      ],
    });
    await actionSheet.present();
  }

  fecharModal() {
    this.modalController.dismiss();
  }

  async abrirNovoCartao() {
    const modal = await this.modalController.create({
      swipeToClose: true, keyboardClose: true, showBackdrop: false, mode: 'ios',
      presentingElement: await this.modalController.getTop(),
      backdropDismiss: true, cssClass: 'swipe-modal',
      component: ManterCartaoPage
    });
    modal.onDidDismiss().then((r: any) => {
      this.carregarCartoes();
      if (r.idCartao)
        this.selecionarMetodo(r);
    });
    await modal.present().catch(err=>console.log(err));
  }

  selecionarMetodo(metodo) {
    if (metodo.pagamentoEntrega && !this.metodoSelecionado) {
      this.promptMetodoPagamentoEntrega();
      return;
    }
    this.presentLoading();
    this.outrosMetodos.map((a: any) => a.selecionado = false)
    this.meusCartoes.map((a: any) => a.selecionado = false)
    metodo.selecionado = true;
    this.metodoSelecionado = metodo;
    setTimeout(() => {
      this.modalController.dismiss(this.metodoSelecionado);
    }, 500);
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      duration: 1000
    });
    await loading.present();
  }
}
