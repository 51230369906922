<section class="px-3 py-5" style="height: 100vh; background-color: white">
  <div class="cardmenu-signin">
    <div class="p-3">
      <div class="row">
        <div class="col">
          <logo [type]="'icon'" [size]="'g'"></logo>
        </div>
        <div class="col d-flex flex-column align-items-end">
          <div>
            <button hidden class="btn btn-link" (click)="close()">Fechar</button>
          </div>
          <div class="dropdown show">
            <a class="btn-light bg-white btn-sm dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img src="assets/img/flag-{{ defaultLanguage }}.svg" style="height: 1.2em" alt="" />
              {{ defaultLanguage == 'pt' ? 'Português' : 'English' }}
            </a>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <a class="dropdown-item border-bottom" (click)="toggleLang('pt')">Português</a>
              <a class="dropdown-item" (click)="toggleLang('en')">English</a>
            </div>
          </div>
        </div>
      </div>
      <br />
      <h1 class="my-0 fw-bolder" style="z-index: 100">
        {{ 'Bem vindo' | translate }} {{ 'ao' | translate }}
        <br />
        <span style="color: #ffc92b">CardMenu </span>
      </h1>
      <br />
      <br />
      <form action="get_started.html" hidden>
        <div class="form-group">
          <label for="exampleInputEmail1">Email</label>
          <input placeholder="{{ 'Insira o' | translate }} email" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
        </div>
        <div class="form-group pt-2">
          <label for="exampleInputPassword1">{{ 'Senha' | translate }}</label>
          <input placeholder="{{ 'Insira a' | translate }} {{ 'senha' | translate }}" type="password" class="form-control" id="exampleInputPassword1" />
        </div>
        <p class="text-right text-warning">{{ 'Esqueceu a senha' | translate }}?</p>
        <button type="submit" class="btn btn-dark btn-lg rounded btn-block">{{ 'Entrar' | translate }}</button>
        <p class="text-center pt-2">
          {{ 'Ainda não possui uma conta' | translate }}? <span class="text-warning">{{ 'Crie aqui' | translate }}</span>
        </p>
      </form>
      <p hidden class="text-muted text-center small m-0 py-3">{{ 'ou' | translate }}</p>
      <div>
        <a class="btn btn-lg my-4 text-white btn-primary btn-block rounded btn-apple" (click)="[authService.SignInWithProvider('facebook'), showLoader()]">
          <i class="fab fa-facebook text-white"></i> &nbsp;{{ 'Entrar com' | translate }} Facebook
        </a>
        <a class="btn mt-4 mb-5 btn-lg text-white btn-block rounded btn-danger" (click)="[authService.SignInWithProvider('google'), showLoader()]">
          <i class="fab fa-google text-white"></i> &nbsp;{{ 'Entrar com' | translate }} Google
        </a>
        <div style="width: 100%; height: 11px; border-bottom: 1px solid #e6e6e6; text-align: center">
          <span style="font-size: 14px; color: #848484; background-color: #fff; padding: 0 10px"> ou </span>
        </div>
      </div>

      <a class="btn my-4 btn-lg btn-block rounded btn-link" (click)="[authService.SignInWithProvider('anonimo'), showLoader()]">
        <i class="fal fa-user"></i> &nbsp;Continuar sem login
      </a>
    </div>

    <div class="row justify-content-center d-flex">
      <div class="col-auto" style="height: 15em" *ngIf="entering">
        <img src="assets/loader.svg" alt="" />
      </div>
    </div>
  </div>
</section>

<section style="width: 100vw; overflow: hidden" hidden>
  <div class="cardmenu-account-setup">
    <div class="row d-flex justify-content-center mt-5 pt-5">
      <div class="col-auto">
        <a class="p-4 text-white m-auto fw-bold d-flex align-items-center h4 text-decoration-none">
          <img src="assets/img/logo.png" style="width: 7em; border-radius: 4px" />
        </a>
      </div>
    </div>
  </div>
  <div class="fixed-bottom fixed-bottom-auto px-4 mx-1 shadow-none pt-4 text-center">
    <div class="row">
      <div class="col p-4 pt-5 rounded" style="background-color: #f7f6fc">
        <a class="btn text-white btn-primary btn-block rounded btn-md btn-apple" (click)="[authService.SignInWithProvider('facebook'), showLoader()]">
          <i class="fab fa-facebook text-white"></i> &nbsp;Entrar com Facebook
        </a>
        <a class="btn text-white btn-lighst btn-block rounded btn-md btn-danger" (click)="[authService.SignInWithProvider('google'), showLoader()]">
          <i class="fab fa-google text-white"></i> &nbsp;Entrar com Google
        </a>
        <a hidden class="btn text-white btn-success rounded btn-block btn-md"> Cadastrar </a>
        <a hidden class="text-dark btn btn-sm btn-block text-decoration-none mb-2">Já tem uma conta? Entre aqui</a>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  </div>
</section>

<br />
<br />
<br />
<br />
<br />
<br />
