import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { PhoneauthPage } from '../acesso/phoneauth/phoneauth.page';


type color = 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark'

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  statusLogin = new Subject();
  modalCanSwipe = new Subject();

  private estabelecimentoAtual = new BehaviorSubject((localStorage.getItem('estabelecimento') ? JSON.parse(localStorage.getItem('estabelecimento')) : null));
  private communicate = {};


  constructor(
    private modalController: ModalController, private toast: ToastController, private nc: NavController, private http: HttpClient) {
  }

  getModalCanSwipe(): Observable<any> {
    return this.modalCanSwipe.asObservable();
  }


  setModalCanSwipe(value) {
    this.modalCanSwipe.next(value)
  }


  abrirLogin() {
    this.modalLogin();
    return this.statusLogin.asObservable();
  }


  async modalLogin() {
    const modal = await this.modalController.create({
      swipeToClose: true, keyboardClose: false, showBackdrop: true, mode: 'ios', backdropDismiss: false,
      cssClass: 'swipe-modal', component: PhoneauthPage
    });
    modal.onDidDismiss().then((d: any) => {
      if (!d.data) this.nc.back();
    });
    await modal.present();
  }

  definirStatusLogin(obj) {
    this.statusLogin.next(obj)
  }


  store(key, value, type?: "ADD" | "SET") {
    const t = (localStorage.getItem(key)) ? JSON.parse(localStorage.getItem(key)) : [];
    localStorage.setItem(key, JSON.stringify((type == 'ADD') ? [...t, value] : t));
  }

  get(key) {
    const t = localStorage.getItem(key);
    if (t) return JSON.parse(t);
    else return false;
  }

  setEstabelecimentoAtual(estabelecimento) {
    this.estabelecimentoAtual.next(estabelecimento);
  }

  getEstabelecimentoAtual(): Observable<any> {
    return this.estabelecimentoAtual.asObservable();
  }

  sendMessage(key, message) {
    if (this.communicate[key]) this.communicate[key]?.next(message);
    else
      this.communicate[key] = new ReplaySubject(message);
  }

  getMessage(key): Observable<any> {
    this.communicate[key] = this.communicate[key] || new ReplaySubject();
    return this.communicate[key]?.asObservable();
  }


  getIp() {
    return this.http.get("//api.ipify.org/?format=json");
  }

  async show(message, color:color='light') {
    const toast = await this.toast.create({
      message: message,
      color: color,
      buttons: [],
      animated: true,
      duration: 4000
      , position: 'top'
    });
    toast.present()
  }


}
