import {
  HttpErrorResponse,
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';
import { SharedService } from '../_services/shared.service';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  isLoading: boolean = false;
  isErrorService: boolean = false;
  errorCodes:Map<number, string> ;


  private token = '';
  constructor(
    private authService:AuthenticationService,
    private sharedService:SharedService,
    private router:Router,
  ) {
    this.errorCodes = new Map();
    this.errorCodes.set(400,'Requisição com formato inválido');
    this.errorCodes.set(401,'Sessão expirada ou inválida');
    this.errorCodes.set(403,'Você não possui permissão');
    this.errorCodes.set(500,'Erro no servidor');
    this.errorCodes.set(502,'Erro no servidor');

    this.token = localStorage.getItem('token')?.toString();
    this.authService.$authState.subscribe(res=>{
      if(res?.token){
        this.token = res.token;
      }
    })
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requisicaoApi:boolean = this.verificarApiUrl(request.url);
    if(requisicaoApi){
      return next.handle(request.clone({
        setHeaders:{
          'Content-Type':'application/json',
          'Authorization': `Bearer ${this.token}`
        }
      })).pipe(catchError((error:HttpErrorResponse)=>{
        var knowErrorMessage = this.errorCodes.get(error.status);
        if(error.status == 0){
          this.sharedService.show('Falha na comunicação com o servidor. Por favor verifique a sua internet','danger')
        }else if(knowErrorMessage){

          if(error.status == 401){
            this.sharedService.show(knowErrorMessage,'danger');
            setTimeout(()=>{
              this.authService.SignOut().then(_=>{
                this.router.navigateByUrl('/',{replaceUrl:true});
              });
            },2000);
          }

          if(error?.error?.message?.length>0){
            this.sharedService.show(error.error.message,'danger');
          }else if(error?.message?.length > 0){
            this.sharedService.show(error?.message,'danger');
          }else{
            this.sharedService.show(knowErrorMessage,'danger');
          }

        }else{
          this.sharedService.show('Ocorreu um erro interno, tente novamente. Caso o erro persista entre em contato com o administrador do sistema.','danger')
        }
        return throwError(error);
      }))
    }else{
      return next.handle(request);
    }

  }


  verificarApiUrl(url:string):boolean{
    let serverUrl = url.split('/');
    var serverAddr = serverUrl[2];
    if(serverAddr && serverAddr?.length>0){
      if(serverAddr == environment.apiHost){
        return true;
      }
    }
    return false;
  }
}