import { TipoCartaoEnum } from "../_enums/Enum";

export class Cartao {
    idCartao:number;
    nome:string;
    cpf:string;
    numeroCartao:number | string  = '';
    bandeiraCartaoEnum:string = 'mastercard';
    tipoCartaoEnum:TipoCartaoEnum = TipoCartaoEnum.CREDITO;
    mesExpiracao:string;
    anoExpiracao:string;
    cartaoPrincipal:boolean;

    codigoSeguranca:string
}


export type CartaoType =  {
    idCartao:number;
    nome:string;
    cpf:string;
    numeroCartao:number;
    bandeiraCartaoEnum:string;
    tipoCartaoEnum:string;
    mesExpiracao:string;
    anoExpiracao:string;
    cartaoPrincipal:boolean;

    //template attributes
    icon:string;
    idMetodoPagamento:number;
    pagamentoOnline:boolean;
}