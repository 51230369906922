import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { of } from 'rxjs';
import { CarrinhoService } from 'src/app/_services/carrinho.service';

@Component({
  selector: 'app-modal-confirmacao-pagamento',
  styleUrls: ['modal-confirmacao-pagamento.component.scss'],
  templateUrl: './modal-confirmacao-pagamento.component.html'
})
export class ModalConfirmacaoPagamentoComponent implements OnInit {
  @Input() idAtendimento;
  estabelecimento: any;
  constructor(private crts: CarrinhoService, private nc: NavController, private modal: ModalController,) {
    const count$ = of(NaN)
  }

  ngOnInit() {

    this.estabelecimento = JSON.parse(localStorage.getItem('estabelecimento'));
  }

  irParaInicio() {
    this.modal.dismiss();
    setTimeout(() => {
      this.nc.navigateRoot(['/']);
    }, 200);
  }


}
