<ion-content style="--ion-background-color: #f3f1f6; max-height: 100vh; height: 45em;">
  <div class="w-100 h-100 mt-n3 px-4 flex-column d-flex justify-content-center align-items-center">
    <logo class="mt-n5 mb-5" [size]="'m'"></logo>
    <br />
    <br />
    <div
      class="animate__animated animate__fadeInUp text-center flex-column d-flex justify-content-center">
      <span class="mb-0" style="font-size: 7em">
        <svg id="successAnimation" class="animated" xmlns="http://www.w3.org/2000/svg" width="150" height="150"
          viewBox="0 0 70 70">
          <path
            id="successAnimationResult"
            fill="#D8D8D8"
            d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z" />
          <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" stroke-width="2"
            stroke-linecap="round" fill="transparent" />
          <polyline id="successAnimationCheck" stroke="#979797" stroke-width="2" points="23 34 34 43 47 27"
            fill="transparent" />
        </svg>
      </span>
      <h2 class="mb-1 mt-0 fw-bold">Pagamento realizado com sucesso!</h2>
      <br />

      <br />
      <ion-button expand="block" class="mb-4 mx-3 mt-5" color="warning" (click)="irParaInicio()">
        <ion-spinner hidden color="light" name="crescent"></ion-spinner>&nbsp;
        <span> Voltar para o cardápio </span>
        <div class="progress">
          <div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25"
            aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </ion-button>

    </div>
  </div>

</ion-content>