import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-menu-footer',
  templateUrl: './menu-footer.component.html',
  styleUrls: ['./menu-footer.component.scss']
})
export class MenuFooterComponent implements OnInit {
  atendimento;
  currentMenu;
  estabelecimentoAtual;

  constructor(private rt: Router, private nc: NavController, private ss: SharedService) {
    this.rt.events.subscribe(val => {
      if (this.rt.url.includes('/cardapio')) this.currentMenu = 'cardapio';
      if (this.rt.url.includes('comanda')) this.currentMenu = 'comanda';
      if (this.rt.url.includes('carrinho')) this.currentMenu = 'carrinho';
      if (this.rt.url.includes('pedidos')) this.currentMenu = 'pedidos';
      if (this.rt.url.includes('favoritos')) this.currentMenu = 'favoritos';
      if (this.rt.url.includes('conta')) this.currentMenu = 'conta';
      if (this.rt.url.includes('inicio')) this.currentMenu = 'inicio';
    });
    this.estabelecimentoAtual = this.ss.getEstabelecimentoAtual();
  }

  ngOnInit() {

  }

  navigate(path) {
    this.nc.navigateForward(path, { replaceUrl: true, animated: true });
  }
}
