import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Atendimento } from 'src/app/_models/atendimento';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { AtendimentoPedidosType } from '../_models/AtendimentoPedidos';

@Injectable({
  providedIn: 'root'
})

export class AtendimentoService {

  constructor(
    private http: HttpClient, 
    private us: UserService,) {
  }

  buscarAtendimentos(status: `ABERTO` | `FECHADO`, param?: { offset: number, limit: number }) {
    return this.http.get(environment.apiUrl + 'atendimentos/' + status + `/${param.offset}/${param.limit}`);
  }

  validarAtendimento(codigoValidacao, idAtendimento) {
    return this.http.post(environment.apiUrl + 'validarAtendimento', {
      "codigoValidacao": codigoValidacao,
      "idAtendimento": idAtendimento,
      "necessitaValidacao": true
    }).toPromise();
  }

  atualizarAtendimento(atendimento: Atendimento, sobrescrever?) {
    const estabelecimento = JSON.parse(localStorage.getItem('estabelecimento'));

    let atendimentos = this.getAtendimentos();

    atendimento.idEstabelecimento = atendimento?.idEstabelecimento || estabelecimento?.idEstabelecimento;
    let at = { ...(!sobrescrever) ? this.getAtendimento(atendimento.idEstabelecimento) : {}, ...atendimento };

    atendimentos = atendimentos?.filter(a => a.idEstabelecimento !== atendimento.idEstabelecimento) || [];
    atendimentos.push(at)

    localStorage.setItem('atendimentos', JSON.stringify(atendimentos));
    return true;
  }

  buscarAtendimentoServer(idAtendimento:number):Observable<AtendimentoPedidosType>{
    return this.http.get<AtendimentoPedidosType>(environment.apiUrl+'atendimentos/basico/'+idAtendimento);
  }

  getAtendimento(idEstabelecimento) {
    if(idEstabelecimento!=null){
      const atendimentos = this.getAtendimentos();
      if (!(atendimentos?.length > 0)) return {};
      return atendimentos.find(a => a.idEstabelecimento == idEstabelecimento);
    }else{
      return null;
    }
  }

  getAtendimentos() {
    const temp = localStorage.getItem('atendimentos');
    if (!temp) return [];
    return JSON.parse(temp);
  }

  reiniciarAtendimento(idEstabelecimento) {
    console.log('reiniciando atendimento ', idEstabelecimento);
    const at: Atendimento = this.getAtendimento(idEstabelecimento);
    const novoAtendimento = { idEstabelecimento: idEstabelecimento, tipoAtendimentoEnum: at.tipoAtendimentoEnum, idMesa: at.idMesa, ip: at.ip, };
    localStorage.removeItem("atendimentos");
    this.atualizarAtendimento(JSON.parse(JSON.stringify(novoAtendimento)));
  }

  finalizaAtendimento(checkout):Observable<any>{
    return this.http.post(environment.apiUrl+'/checkoutAtendimento', checkout);
  }

  //Busca a comanda mais recente no estabelecimento especificado
  buscarComandaRecenteEstabelecimento(idEstabelecimento:number):Observable<AtendimentoPedidosType>{
    return this.http.get<AtendimentoPedidosType>(environment.apiUrl+'atendimentos/buscarComandaAbertaEstabelecimento?idEstabelecimento='+idEstabelecimento)
  }


}
