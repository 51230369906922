import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';
import { ApplicationRef, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PwaService } from '../_services/pwa.service';


@Component({
  selector: 'app-splash-screen',
  template: `
  <ion-progress-bar color="warning" style="    z-index: 10000;" type="indeterminate"  *ngIf="show"></ion-progress-bar>
     <div class="splash-screen d-flex align-items-center justify-content-center flex-column" *ngIf="show" @fadeOut>
     <h1 style="color:'white'; font-size: 3em">cardmenu</h1>
          <br>
          <div  class="ion-text-center">
          <ion-spinner name="crescent"></ion-spinner>
          </div>
          <div class="fixed-bottom text-muted text-center">{{version}} </div>  
      </div>
  `,
  animations: [
    // the fade-in/fade-out animation.
    trigger('fadeOut', [
      transition(':leave', [
        query(':leave', animateChild(), { optional: true }),
        animate(300, style({ opacity: 0 }))
      ]),
    ]),
  ],
  styles: [`
      .splash-screen {
        position: absolute;
        top: 0;
        display: flex;
        right: 0;
        justify-content: center;
        bottom: 0;
        height: 100vh;
        align-items: center;
        background: #141414;
        width: 100vw;
        left: 0;
        z-index: 9999;
      }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplashScreenComponent implements OnInit {
  show = true;


  version;
  constructor(
    private pwaService: PwaService,
    private cdr: ChangeDetectorRef,
    private appRef: ApplicationRef,
  ) {
    this.version = environment.version;
  }

  ngOnInit() {
    this.pwaService.checkForUpdate()
      .subscribe(result => {
        this.show = result;
        this.cdr.detectChanges();
      });
    setTimeout(() => {
      this.show = false;
    }, 2000);
  }
}

