import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ProdutoQuantityComponent } from './dashboard/cardapio/produto-quantidade.component';
import { HeaderCommomComponent } from './_components/header-commom.component';
import { LoadingComponent } from './_components/loading.component';
import { LogoComponent } from './_components/logo.component';
import { MenuFooterComponent } from './_components/menu-footer/menu-footer.component';
import { ModalConfirmacaoPagamentoComponent } from './_components/modal-confirmacao-pagamento/modal-confirmacao-pagamento.component';
import { ModalConfirmacaoPedidoComponent } from './_components/modal-confirmacao-pedido/modal-confirmacao-pedido.component';
import { ModalEnderecoComponent } from './_components/modal-endereco/modal-endereco.component';
import { ModalPixComponent } from './_components/modal-pix/modal-pix.component';
import { ModalRevisarPedidoComponent } from './_components/modal-revisar-pedido/modal-revisar-pedido.component';
import { ModalSelecionarPagamentosComponent } from './_components/modal-selecionar-pagamentos/modal-selecionar-pagamentos.component';
import { ExibirEnumPipe } from './_helpers/exibir-enum.pipe';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    MenuFooterComponent,
    LoadingComponent,
    LogoComponent,
    ModalConfirmacaoPagamentoComponent,
    ModalEnderecoComponent,
    ModalRevisarPedidoComponent,
    ModalConfirmacaoPedidoComponent,
    ModalSelecionarPagamentosComponent,
    ModalPixComponent,
    HeaderCommomComponent,
    ProdutoQuantityComponent,
    ExibirEnumPipe,
  ],
  imports: [
    IonicModule,
    TranslateModule,
    NgxQRCodeModule,
    CommonModule, 
    RouterModule, 
    FormsModule,
  ],
  exports: [MenuFooterComponent, HeaderCommomComponent, LoadingComponent, ProdutoQuantityComponent, LogoComponent, ExibirEnumPipe],
  providers:[MaskPipe]
})
export class SharedModule { }