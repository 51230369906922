import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { AtendimentoService } from 'src/app/_services/atendimento.service';
import { firestoreService } from 'src/app/_services/firestore.service';

@Component({
  selector: 'app-modal-validacao-atendimento',
  templateUrl: './modal-validacao-atendimento.component.html',
})
export class ModalValidacaoAtendimentoComponent implements OnInit {
  @Input() idAtendimento;
  hashAtendimento;
  title = 'app';
  elementType = 'url';
  loading;
  estabelecimento;
  constructor(
    private tc: ToastController,
    private fs: firestoreService,
    private as: AtendimentoService,
    public modalController: ModalController
  ) { }

  ngOnInit() {
    this.fs.doc$('/ATENDIMENTOS/' + this.idAtendimento).subscribe((r: any) => {
      if (r?.statusAtendimento?.status == 'CONCLUIDO' || r?.statusAtendimento?.status == 'ABERTO') {
        this.as.atualizarAtendimento({ idAtendimento: this.idAtendimento, necessitaValidacao: false })
        this.modalController.dismiss({ ok: true });
      }
    })
  }

  validar() {
    if (!(this.hashAtendimento.length > 3)) return;
    this.loading = true;
    this.as.validarAtendimento(this.hashAtendimento, this.idAtendimento).then(a => {
      this.loading = false;
      this.as.atualizarAtendimento({ idAtendimento: this.idAtendimento, necessitaValidacao: false });
      this.modalController.dismiss({ ok: true });
    }).catch(a => {
      console.log(a)
      this.loading = false;
    });
  }

  async showToast(message, color) {
    const toast = await this.tc.create({ message: message, animated: true, color: color, duration: 2500, position: 'bottom' });
    toast.present()
  }

}
