import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Endereco } from '../_models/Endereco';

@Injectable({
  providedIn: 'root'
})

export class EnderecoService {
  constructor(
    private http: HttpClient
    ) {
  }

  getEstados() {
    return this.http.get(environment.apiUrl+'estados');
  }

  tornarPrincipal(idEndereco) {
    return this.http.put(environment.apiUrl+'enderecoPrincipal/'+idEndereco, {});
  }

  buscarPrincipal():Observable<any>{
    return this.http.get<any>(environment.apiUrl+'enderecoPrincipal');
  }


  getCidades(idEstado) {
    return this.http.get(environment.apiUrl+'estados/'+idEstado+'/cidades/true');
  }

  getEnderecos():Observable<Array<Endereco>> {
    return this.http.get<Array<Endereco>>(environment.apiUrl+'enderecos');
  }

  deleteEndereco(idEndereco) {
    return this.http.delete(environment.apiUrl+'endereco/'+idEndereco);
  }

  salvarEndereco(endereco) {
    return this.http.post(environment.apiUrl+'endereco', endereco)
  }

}
