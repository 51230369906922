import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { AddEnderecosPage } from 'src/app/conta/add-enderecos/add-enderecos.page';
import { EnderecoService } from 'src/app/_services/endereco.service';
import { PedidoService } from 'src/app/_services/pedido.service';

@Component({
  selector: 'app-modal-endereco',
  templateUrl: './modal-endereco.component.html',
  styleUrls: ['./modal-endereco.component.scss'],
})
export class ModalEnderecoComponent implements OnInit {
  @Input() estabelecimento;
  loading = true;
  @Input() enderecoSelecionado;
  retirar = { id: 0, slug: 'RETIRAR_NO_LOCAL', nomeEndereco: 'Retirar no local', logradouro: 'Travessa Guimarães Rosa', numeroLogradouro: 10, valorFrete: 0 };
  enderecos;
  constructor(private modalController: ModalController, private loadingController: LoadingController, private ps: PedidoService, private es: EnderecoService,) { }

  ngOnInit() {
    this.carregarEnderecos();
  }

  fecharModal() {
    this.modalController.dismiss(this.enderecoSelecionado);
  }

  async carregarEnderecos() {
    this.loading = true;
    let loader = this.loadingController.create();
    (await loader).present();
    this.enderecos = this.es.getEnderecos();
    this.enderecos.subscribe(null, null, () => this.loadingController.dismiss());
    setTimeout(() => {
      this.loading = false;
    }, 1000);

  }

  selecionarEndereco(endereco) {
    this.enderecoSelecionado = endereco;
    //this.MyProp.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  @ViewChild("botaoSelecionar") MyProp: ElementRef;

  scroll(id) {

    const el = document.getElementById(id);
    // el.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: AddEnderecosPage,
      mode: 'ios',
      swipeToClose: true,
      backdropDismiss: true,
      keyboardClose: true,
      showBackdrop: true,
      animated: true,
      cssClass: 'h-100 auto-height',
      presentingElement: await this.modalController.getTop(),
    });
    modal.onDidDismiss().then(r => {
      if (r.data)
        setTimeout(() => {
          this.modalController.dismiss(r.data);
        }, 1);
      //this.carregarEnderecos();
    });
    return await modal.present()
  }

}
