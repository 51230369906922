import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ToastController } from '@ionic/angular';
import * as Sentry from "@sentry/browser";
import CryptoJS from "crypto-js";
import firebase from 'firebase/compat/app';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { firestoreService } from "./firestore.service";
import { SharedService } from './shared.service';
import { UserService } from './user.service';
import { Usuario } from '../_models/Usuario';


@Injectable({ providedIn: "root" })
export class AuthenticationService {
  userData;
  $authState = new Subject<any>();
  token: any;
  constructor(
    public fs: firestoreService,
    private ss: SharedService,
    public afAuth: AngularFireAuth,
    private toastController: ToastController,
    private UserService: UserService,
    private http: HttpClient
  ) {

  }





  SignInWithProvider(metodo) {
    if (metodo == 'anonimo') {
      this.afAuth.signInAnonymously().then(r => this.thenPromiseSignInWithProvider(r)).catch(r => this.show(r));
      return;
    }
    let provider;
    switch (metodo) {
      case 'facebook':
        provider = new firebase.auth.FacebookAuthProvider()
        break;
      case 'google':
        provider = new firebase.auth.GoogleAuthProvider()
        break;
    }

    if (navigator.userAgent.includes("Instagram") || navigator.userAgent.includes("FBAN"))
      this.afAuth.signInWithRedirect(provider).then(r => this.thenPromiseSignInWithProvider(r)).catch(r => this.show(r));
    else
      this.afAuth.signInWithPopup(provider).then(r => this.thenPromiseSignInWithProvider(r)).catch(r => this.show(r));

  }

  async thenPromiseSignInWithProvider(r) {
    try{
      const usert = JSON.parse(JSON.stringify(r.user));
      const user: any = {
        name: usert.displayName || usert.name,
        email: usert.email,
        phoneNumber:
          usert.phoneNumber,
      }
      user.id = usert.uid || usert.id;
      user.photoURL = r?.additionalUserInfo?.profile?.picture?.data?.url || usert.photoURL;
      
      this.setCurrentUser(user);
      await this.fs.set('/infrastructure/access/users/' + usert.id, JSON.parse(JSON.stringify(user)), true)
      return true;
    }catch(err){
      return false;
    }
  }

  getRedirectResult() {
    return this.afAuth.getRedirectResult();
  }


  async show(message, color?) {
    const toast = await this.toastController.create({
      message: message,
      buttons: [],
      color: color,
      animated: true,
      duration: 4000
      , position: 'top'
    });
    toast.present()
  }

  setCurrentUser(user) {
    const usuario = { nome: user.name, uuid: user.id, email: user?.email, tipoUsuario: 'CLIENTE', linkImagem: user?.photoURL, telefone1: user?.phoneNumber, dataNascimento:user.dataNascimento }
    if (!user) return;
    this.http.post(environment.apiUrl + 'login', usuario).subscribe(async (res: Usuario) => {
      if (res.token) {
        localStorage.setItem(
          "token", res.token,
        );
        this.$authState.next(res);
        this.ss.definirStatusLogin(res);
        localStorage.setItem(
          btoa("user"),
          CryptoJS.AES.encrypt(
            JSON.stringify(res),
            environment.keys.storage
          ).toString()
        );
      }
      Sentry.configureScope((scope) => {
        scope.setUser({
          'idUsuario': res?.idUsuario,
          'username': res?.nome,
          'email': res?.email,
          'phone': res?.telefone1
        });
        scope.setTag('version', environment.version);
        scope.setTag('isIos', localStorage.getItem('isIos'));
        scope.setTag('isInStandaloneMode', localStorage.getItem('isInStandaloneMode'));
      });
      res.uuid = res.uuid || user.id;
    })
  }

  estaLogado(): boolean {
    const user = this.UserService.getCurrentUser();
    return user !== null && user.idUsuario 
      ? true
      : false;
  }

  authState(): Observable<any> {
    return this.$authState as Observable<any>;
  }

  SignOut() {
    const estabelecimento: any = JSON.parse(localStorage.getItem('estabelecimento'));
    const logout = this.afAuth.signOut();
    console.log('logout...')
    logout.then(() => {
      localStorage.removeItem("newUser");
      localStorage.removeItem(btoa("user"));
      localStorage.removeItem("token");
      setTimeout(() => {
        window.location.reload();
      }, 100);
    });
    return logout;
  }

  forcarAutenticacao() {
    return
    const a = new BehaviorSubject(false);
    if (!this.estaLogado()) a.next(false);
    this.$authState.subscribe((r: any) => {
      if (r) {
        a.next(r);
        // a.complete();
      }
    });
    return a;
  }

}



