import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { firestoreService } from 'src/app/_services/firestore.service';

@Component({
  selector: 'app-modal-confirmacao-pedido',
  styleUrls: ['modal-confirmacao-pedido.component.scss'],
  templateUrl: './modal-confirmacao-pedido.component.html'
})
export class ModalConfirmacaoPedidoComponent implements OnInit {
  estabelecimento
  @Input() carrinho
  @Input() atendimento;
  passo = 0;
  constructor(
    private fs: firestoreService, 
    private router: Router, 
    private modal: ModalController,) {
  }

  async ngOnInit() {
    setTimeout(() => {
      this.passo += 1;
    }, 1000);
    if (this.atendimento?.metodoPagamento?.pagamentoOnline) {
      console.log('pagamento online')
    }
    this.passo += 1;

    setTimeout(() => {
      this.passo += 1;
    }, 2000);
    setTimeout(() => {
      this.passo += 1;
    }, 3000);



    this.estabelecimento = JSON.parse(localStorage.getItem('estabelecimento'));
  }

  pagamentoConfirmado() {
    return new Promise((res, rej) => {
      this.fs.doc$('ATENDIMENTOS/' + this.atendimento?.idAtendimento).subscribe((r: any) => {
        if (r?.statusPagamento?.status == 'CONCLUIDO') {
          res(true);
        }
      })
    })
  }



  irParaInicio() {
    this.modal.dismiss();
    setTimeout(() => {
      this.router.navigate(['/pedidos']);
    }, 200);
  }

  abrirComanda() {
    this.modal.dismiss();
    setTimeout(() => {
      this.router.navigate(['/comanda']);
    }, 200);
  }

}
