import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSelect, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import firebase from 'firebase/compat/app';
import { timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UserService } from 'src/app/_services/user.service';
import { WindowService } from 'src/app/_services/window.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-phoneauth',
  templateUrl: './phoneauth.page.html',
  styleUrls: ['./phoneauth.page.scss'],
})
export class PhoneauthPage implements OnInit {
  @ViewChild('selectCountry', { static: false }) selectRef: IonSelect;

  code = [];
  windowRef: any;
  asa = 3;
  logado = false;
  phoneNumber;
  countries;
  verificationCode: string;
  userVerified: any;
  codeSent = false;
  timer = 0;
  countrySelected = { name: 'Brasil', callingCode: '55' };
  msg = { error: null };
  @Output() continue = new EventEmitter<any>();
  loading = true;

  constructor(private win: WindowService, private router: Router,
    private http: HttpClient,
    public modalController: ModalController,
    private as: AuthenticationService,
    private translate: TranslateService,
    private userService: UserService) {
    this.translate.get('Bem vindo!!').subscribe(r => { console.log(r) })
  }

  ngOnInit() {
    this.countries = this.http.get('https://restcountries.com/v2/all').pipe(map((res: any) => res.filter(r => r?.callingCodes[0]).map(r => {
      return { name: r.name, callingCode: Number(r.callingCodes[0]) };
    })));
    this.windowRef = this.win.windowRef;
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
    });
    this.windowRef.recaptchaVerifier.render();
    this.loading = false;
  }

  openSelectCountry() {
    this.selectRef.open()
  }

  iniciarContagem() {
    this.timer = 30;
    const source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.timer = this.timer - 1;
      if (this.timer == 0)
        abc?.unsubscribe();
    });
  }

  reenviar() {
    if (this.timer > 0) return;
    this.codeSent = false;
  }

  sendLoginCode() {
    const nb = this.phoneNumber.replace(/\D+/g, '');
    this.loading = true;
    const appVerifier = this.windowRef.recaptchaVerifier;
    if (!firebase.apps.length)
      firebase.initializeApp(environment.firebase);

    firebase.auth().signInWithPhoneNumber('+' + this.countrySelected?.callingCode + '' + nb, appVerifier)
      .then(result => {
        this.codeSent = true;
        this.iniciarContagem();
        this.windowRef.confirmationResult = result;
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      })
      .catch(error => {
        this.msg.error = error.message;
        this.loading = false;
        console.log(error);
      });
  }

  alterarNumero(){
    this.codeSent=false;
    this.phoneNumber = '';
  }

  verifyLoginCode(bypass?) {
    this.loading = true;
    this.windowRef.confirmationResult
      .confirm(this.verificationCode)
      .then(result => {
        console.log(result);
        this.loginSuccess(result);
      }).catch(error => {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
        if (!bypass)
          this.msg['error'] = 'Humm, parece que não foi esse o código que te enviamos :(';
      });

  }

  onOtpChange(e) {
    this.verificationCode = e;
    if (e.length >= 6)
      this.verifyLoginCode(true);
  }

  async loginSuccess(result) {
    this.logado = true;
    this.modalController.dismiss(result);
    this.as.thenPromiseSignInWithProvider(result).then(res=>{
      if(res){
        this.translate.get('Bem vindo!!').subscribe(r => {
          this.msg['success'] = r + '';
          this.userVerified = result.user;
          Swal.fire({
            text: this.msg['success'],
            icon: 'success',
            showConfirmButton: false,
            timer: 1000,
          });
        });
      }else{
        this.translate.get('Falha na autenticação.').subscribe(r => {
          this.msg['error'] = r + '';
          this.userVerified = result.user;
          Swal.fire({
            text: this.msg['error'],
            icon: 'error',
            showConfirmButton: false,
            timer: 1000,
          });
        });
      }
    });
  }

  loginAnonimo() {
    this.loading = true;
    this.as.SignInWithProvider('anonimo')
  }


}
