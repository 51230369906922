export class Endereco {
  "bairro": string;
  "cep": string;
  "complemento": string;
  "idCidade": number;
  "idEndereco": number;
  "latitude": string;
  "logradouro": string;
  "longitude": string;
  "uf": string;
  "ibge": number;
  "localidade": string;
  "all": string;
  "nomeEndereco": string;
  "numeroLogradouro": string;
  "principal": boolean;
  "siglaEstado": string;
}