import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { firestoreService } from 'src/app/_services/firestore.service';
import { environment } from 'src/environments/environment';
import { ModalConfirmacaoPedidoComponent } from '../_components/modal-confirmacao-pedido/modal-confirmacao-pedido.component';
import { ModalValidacaoAtendimentoComponent } from '../_components/modal-validacao-atendimento/modal-validacao-atendimento.component';
import { Atendimento, tipoAtendimentoEnum } from '../_models/atendimento';
import { AtendimentoService } from './atendimento.service';
import { CarrinhoService } from './carrinho.service';
import { PagamentoService } from './pagamento.service';
import { Router } from '@angular/router';
import { SolicitaValidaAtendimentoType } from '../_models/solicitaValidaAtendimento';
import { Observable } from 'rxjs';
import { StatusAtendimentoEnum } from '../_enums/Enum';

@Injectable({
  providedIn: 'root'
})

export class PedidoService {

  private idAtendimento:string | number;

  constructor(
    private toastController: ToastController,
    private cs: CarrinhoService,
    private ps: PagamentoService,
    private fs: firestoreService,
    private modalController: ModalController,
    private router:Router,
    private ats: AtendimentoService, 
    private http: HttpClient) {
  }


  /**
   * valor mínimo frete grátis
   * @returns 
   * Este serviço é resposavel por SOLICITAR o pedido e o atendimento do cliente, tanto quanto pedido de demanda, tanto pedido de comanda. Quando enviar o idAtendimento ele irá solicitar um novo pedido, quando não enviar o idAtendimento ele irá verificar se precisa solicitar a validação e após será soliictado o pedido
   * @param user 
   * @param cupomDesconto: "string",
   * @param idAtendimento: 0,
    * @param idCartao: 0,
    * @param idEndereco: 0,
    * @param idEstabelecimento: 0,
    * @param idMesa: 0,
    * @param idMetodoPagamento: 0,
    * @param idProduto: 0,
    * @param ip: "string",
    * @param listProdutoEntity: [],
    * @param obs: "string",
    * @param pagamentoNaEntrega: false,
    * @param possuiAgregado: false,
    * @param quantidade: 0,
    * @param tipoAtendimentoEnum: "string",
    * @param tipoEntregaEnum: "string",
    * @param tipoPagamentoEnum: "string",
    * @param troco: 0,
    * @param valor: 0,
    * @param valorTotal: 0
   */
  async solicitaPedido(pedido: Atendimento) {
    pedido = JSON.parse(JSON.stringify(pedido));
    pedido.listProdutoEntity?.map((p: any) => {
      delete p.indice;
      delete p.linkImagem;
      delete p.icon;
      delete p.nomeProduto;
      delete p.precoProdutoSemAdicionais;
      delete p.precoProdutoSemAdicionais;
      p.listPassosProdutoAgregadoEntity = p.listPassosProdutoAgregadoEntity?.map(a => {
        a.listProdutoAgregadosEntity = a.listProdutoAgregadosEntity?.filter(r => r.quantidade)?.map(b => {
          delete b.nomeProduto;
          delete b.listProdutoAgregadosEntity;
          return b;
        });
        delete a.nomePassoProduto;
        delete a.minimo;
        delete a.maximo;
        return a;
      });
      return p;
    });
    delete pedido.metodoPagamento;
    const req = this.http.post<SolicitaValidaAtendimentoType>(environment.apiUrl + 'solicitaPedido', pedido);
    return req.toPromise();
  }


  validateCoupon(voucher, shop_id) {
    return this.http.post(environment.apiUrl+'/coupons-validate/'+voucher, { shop_id: shop_id });
  }

  /*
  Este serviço é responsável por retornar os dados de checkout dos tipoDadosCheckoutEnum: 
  (DADOS_FRETE, VALIDA_CUPOM_DESCONTO, METODO_PAGAMENTO_ACEITOS)
  Sendo: 
  DADOS_FRETE => enviar idEstabelecimento, idEndereco será retornado valorFrete, valorFreteGratis, valorMinimoRaioFreteGratis 
  VALIDA_CUPOM_DESCONTO => enviar idEstabelecimento, cupomDesconto será retornado cupomDesconto caso válido ou uma exceção caso não válido 
  METODO_PAGAMENTO_ACEITOS => enviar idEstabelecimento será retornado List<MetodoPagamentoEntity>
  */
  getDadosCheckout(tipoDadosCheckoutEnum: 'DADOS_FRETE' | 'VALIDA_CUPOM_DESCONTO' | 'METODO_PAGAMENTO_ACEITOS', dados: {}):Observable<any> {
    return this.http.post<any>(environment.apiUrl+'/dadosCheckout/'+tipoDadosCheckoutEnum, dados);
  }


  
  async prepararPedido(atendimento) {
    if (!atendimento.idEstabelecimento) atendimento.idEstabelecimento = JSON.parse(localStorage.getItem('estabelecimento'))['idEstabelecimento'];
    let cart;
    this.cs.getCarrinho(atendimento.idEstabelecimento).subscribe(carrinho => {
      cart = carrinho;
      atendimento.idMetodoPagamento = atendimento?.metodoPagamento?.idMetodoPagamento;
      atendimento.troco = Number(atendimento.troco);
      atendimento.valor = carrinho.valor;
      atendimento.ip = atendimento.ip || localStorage.getItem('ip');
      atendimento.listProdutoEntity = carrinho.listProdutoEntity;
    });


    return new Promise((resolve, reject) => {
      delete atendimento?.enderecoEntity?.idCidade
      const pedidoPromise = this.solicitaPedido(atendimento);

      pedidoPromise.then(res => {
        this.idAtendimento = res.idAtendimento + '';
   
        if (atendimento.tipoAtendimentoEnum == tipoAtendimentoEnum.DELIVERY) {
          delete res.idAtendimento;
          delete res.necessitaValidacao;
          delete res.hashAtendimento;
        }
        
        this.ats.atualizarAtendimento({ idEstabelecimento: atendimento.idEstabelecimento, ...JSON.parse(JSON.stringify(res)) })

       
        if (res?.necessitaValidacao) {
          this.fs.set('ATENDIMENTOS/' + res?.idAtendimento, { statusAtendimento: {}, ...JSON.parse(JSON.stringify(res)) })
          this.abrirValidacao(res?.idAtendimento).then(r => {
            confirma();
          });
        }
        else {
          if (atendimento?.metodoPagamento?.pagamentoOnline)
            this.ps.gerarCobrancaFirebase(this.idAtendimento, atendimento.idEstabelecimento, atendimento.idUsuario);

          if (atendimento?.metodoPagamento?.metodoPagamento?.toUpperCase() == 'PIX' && res.pixCopiaCola) {
            const promise = this.ps.abrirModalPix({ ...atendimento, idAtendimento: this.idAtendimento }, res?.pixCopiaCola);
            promise.then((r:any) => {
              if(r?.status=='PAGO'){
                confirma();
              }else if(r?.status=='AGUARDANDO_PAGAMENTO'){
                setTimeout(() => {
                  this.cs.cleanCarrinho(atendimento.idEstabelecimento);
                }, 300);
                this.showToast('Aguardando pagamento', 'warning');
                this.router.navigate(['/pedidos']);
              }
            });
            return promise;
          }
        }

        if(!res?.necessitaValidacao && atendimento.tipoAtendimentoEnum == tipoAtendimentoEnum.COMANDA)
          confirma();

        return pedidoPromise;
      }).catch(a => {
        console.log(a)
        reject('Erro');
      });
      const confirma = (async () => {
        const modal = await this.modalController.create({
          swipeToClose: false, keyboardClose: false, showBackdrop: true, cssClass: 'h-100',
          mode: 'ios', backdropDismiss: false, componentProps: { atendimento: { ...atendimento, idAtendimento: this.idAtendimento}, carrinho: cart },
          component: ModalConfirmacaoPedidoComponent
        });
        resolve('ok');
        modal.onDidDismiss();
        setTimeout(() => {
          this.cs.cleanCarrinho(atendimento.idEstabelecimento);
        }, 300);
        await modal.present();
      }).bind(this);
    });
  }


    async confirmarPedido(atendimento:Atendimento, carrinho:any){
      const modal = await this.modalController.create({
        swipeToClose: false, keyboardClose: false, showBackdrop: true, cssClass: 'h-100',
        mode: 'ios', backdropDismiss: false, componentProps: { atendimento: { ...atendimento, idAtendimento: this.idAtendimento}, carrinho: carrinho },
        component: ModalConfirmacaoPedidoComponent
      });
      modal.onDidDismiss();
        setTimeout(() => {
          this.cs.cleanCarrinho(atendimento.idEstabelecimento);
        }, 300);
      await modal.present();
    }

  async showToast(message, color) {
    const toast = await this.toastController.create({ message: message, animated: true, color: color, duration: 2500, position: 'top' });
    toast.present()
  }



  abrirValidacao(idAtendimento) {
    return new Promise(async (res, rej) => {
      const modal = await this.modalController.create({
        cssClass: ' swipe-modal',
        swipeToClose: false, keyboardClose: false, showBackdrop: true, mode: 'ios', componentProps:
          { 'idAtendimento': idAtendimento }, backdropDismiss: false,
        component: ModalValidacaoAtendimentoComponent
      });
      await modal.present();
      modal.onDidDismiss().then((d: any) => {
        res(d.data);
      });
    });
  }

}
