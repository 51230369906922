import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'logo',
  template: `
    <div class="title d-flex  justify-content-center align-items-center">
      <a class="text-decoration-none justify-content-center text-dark d-flex align-items-center">
        <img style="border:0px solid white;" class="cardmenu-logo rounded me-2"  [ngClass]="{'animate__animated  animate__fadeInUp': type=='splash' || type=='icon'}" style="height: {{(size=='g') ? '6em' : (size=='m') ? '4em' : '2em' }};" src="assets/img/icon.png">
        <p *ngIf="type!='icon'" class="text-center animate__animated  animate__fadeInUp my-2 logo-title"  [ngClass]="{'text-white  animate__animated  animate__fadeInUp': type=='splash' || type=='icon'}"
          style="font-size: {{(size=='g') ? '3.5em' : (size=='m') ? '2.5em' : '1.5em' }}; color: black; font-weight: bold;"> cardmenu
        </p>
      </a>
</div>`
})
export class LogoComponent implements OnInit {
  @Input() type;
  @Input() size;
  constructor() { }

  ngOnInit() { }

}
