<ion-content style="--ion-background-color: #f3f1f6; max-height: 100vh">
  <div class="w-100 h-100 mt-n3 flex-column d-flex justify-content-center align-items-center">
    <logo class="mt-n5 mb-5" [size]="'m'"></logo>
    <br />
    <br />
    <div *ngIf="passo < 4">
      <ul class="p-0">
        <li [ngClass]="{ done: passo > 0, current: passo == 0, todo: passo < 0 }">
          <ion-spinner *ngIf="passo == 0" name="crescent"></ion-spinner>&nbsp;
          <ion-icon *ngIf="passo > 0" name="checkmark-outline"></ion-icon>
          Preparando pedido
        </li>
        <li *ngIf="atendimento?.metodoPagamento?.pagamentoOnline"
          [ngClass]="{ done: passo > 1, current: passo == 1, todo: passo < 1 }">
          <ion-spinner *ngIf="passo == 1" name="crescent"></ion-spinner>&nbsp;
          <ion-icon *ngIf="passo < 1" name="ellipsis-horizontal-outline"></ion-icon>
          <ion-icon *ngIf="passo > 1" name="checkmark-outline"></ion-icon>
          Confirmando pagamento
        </li>
        <li [ngClass]="{ done: passo > 2, current: passo == 2, todo: passo < 2 }">
          <ion-spinner *ngIf="passo == 2" name="crescent"></ion-spinner>&nbsp;
          <ion-icon *ngIf="passo < 2" name="ellipsis-horizontal-outline"></ion-icon>
          <ion-icon *ngIf="passo > 2" name="checkmark-outline"></ion-icon>
          <span *ngIf="atendimento?.tipoAtendimentoEnum == 'DELIVERY'"> Enviando para o whatsapp</span>
          <span *ngIf="atendimento?.tipoAtendimentoEnum != 'DELIVERY'"> Enviando pedido</span>
        </li>
        <li [ngClass]="{ done: passo > 3, current: passo == 3, todo: passo < 3 }">
          <ion-spinner *ngIf="passo == 3" name="crescent"></ion-spinner>&nbsp;
          <ion-icon *ngIf="passo < 3" name="ellipsis-horizontal-outline"></ion-icon>
          <ion-icon *ngIf="passo > 3" name="checkmark-outline"></ion-icon>
          Pedido feito
        </li>
      </ul>
    </div>
    <div *ngIf="passo > 3"
      class="animate__animated animate__fadeInUp text-center flex-column d-flex justify-content-center">
      <span class="mb-0" style="font-size: 7em">
        <svg id="successAnimation" class="animated" xmlns="http://www.w3.org/2000/svg" width="150" height="150"
          viewBox="0 0 70 70">
          <path
            id="successAnimationResult"
            fill="#D8D8D8"
            d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z" />
          <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" stroke-width="2"
            stroke-linecap="round" fill="transparent" />
          <polyline id="successAnimationCheck" stroke="#979797" stroke-width="2" points="23 34 34 43 47 27"
            fill="transparent" />
        </svg>
      </span>
      <h2 class="mb-1 mt-0 fw-bold">Pronto!</h2>
      <span class="text-muted" hidden>Seu pedido foi enviado para o estabelecimento</span>
      <br />

      <p class="h6 mt-3 text-muted" *ngIf="atendimento?.idAtendimento">Atendimento nº: {{ atendimento?.idAtendimento }}
      </p>
      <p class="text-center" hidden>Enviando seu pedido para o whatsapp. <br /></p>
      <p class="text-center" hidden>Caso não seja enviado, clique no botão abaixo</p>
      <br />
      <br />
      <ion-button *ngIf="atendimento?.tipoAtendimentoEnum != 'COMANDA'" expand="block" class="mb-4 mt-5" color="warning" (click)="irParaInicio()">
        <ion-spinner hidden color="light" name="crescent"></ion-spinner>&nbsp;
        <span> Meus pedidos </span>
        <div class="progress">
          <div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25"
            aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </ion-button>
      <ion-button
        expand="block" class="mb-4 mt-5" color="warning"
        *ngIf="atendimento?.tipoAtendimentoEnum == 'COMANDA'"
        (click)="abrirComanda()">
        <span> Ver minha comanda </span>
      </ion-button>
    </div>
  </div>

  <div class="ps-2 pe-3 pb-5 pt-2" hidden>
    <div class="row g-0">
      <div class="col-3 d-flex justify-content-center">
        <img class="img-fluid mt-3 rounded float-left" style="height: 3em"
          src="{{ estabelecimento?.linkFotoEstabelecimento }}" />
      </div>
      <div class="col">
        <h6>Pedido enviado (N°: {{ atendimento?.idAtendimento }})</h6>
        <p hidden>Previsão de entrega para 14:32</p>
        <br />
      </div>
    </div>
  </div>
  <div hidden class="mt-n5 h-503 bg-white px-5 py-4" style="border-radius: 4em 4em 0 0">
    <div *ngFor="let c of carrinho?.listProdutoEntity" class="px-1 border-bottom item-carrinho py-2">
      <div class="row g-0 py-1">
        <div class="col-3 d-flex align-items-center" hidden *ngIf="c?.linkImagem">
          <div
            [ngStyle]="{ 'background-image': 'url(' + c?.linkImagem + ')' }"
            class="rounded ms-2 me-3"
            style="width: 4em; height: 100%; background-size: cover; background-position: center center; background-repeat: no-repeat">
          </div>
        </div>

        <div class="col d-flex align-items-start">
          <div class="align-items-start w-100 flex-column d-flex">
            <div class="row g-0 w-100">
              <div class="col-8">
                <p class="fw-bold mb-0 text-dark" style="line-height: 1rem">{{ c?.quantidade }}x {{ c?.nomeProduto }}
                </p>
              </div>
              <div class="col-4 text-end">
                <small class="fw-bold">
                  {{ (c?.precoProdutoComAdicionais || c?.precoProduto) * c?.quantidade | currency:
                  'BRL':'symbol-narrow':'1.2-2' }}
                </small>
              </div>
            </div>
            <p class="fw-bold mb-2 ms-3 text-dark" style="line-height: 1rem">
              <span class="small text-muted font-italic" *ngIf="c?.observacao"> ({{ c?.observacao }})<br /></span>
              <small class="text-muted" style="width: 140%">
                <span *ngFor="let adicional of c?.listPassosProdutoAgregadoEntity">
                  <span *ngFor="let item of adicional?.listProdutoAgregadosEntity" [hidden]="!item.quantidade">
                    <span hidden> {{ adicional?.nomePassoProduto ? adicional?.nomePassoProduto + ':' : '' }}</span>
                    {{ item?.quantidade }}x {{ item?.nomeProduto }} - {{ item?.precoProduto * item?.quantidade |
                    currency: 'BRL':'symbol-narrow':'1.2-2' }} <br /></span></span>
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>