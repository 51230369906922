import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import CryptoJS from "crypto-js";
import { environment } from 'src/environments/environment';
import { Usuario } from '../_models/Usuario';
import { Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class UserService {

    public usuarioAtualizado = new Subject<any>();
    
    constructor(private http: HttpClient) {
    }


    update(user) {
        return this.http.put(environment.apiUrl + 'users', user).toPromise();
    }

    getDadosUsuario(escopo?) {
        escopo = escopo || 'BASIC';
        return this.http.get(environment.apiUrl + 'meus-dados', { params: { scope: escopo } });
    }

    salvarDados(dados) {
        return this.http.put(environment.apiUrl + '/meus-dados', dados).toPromise()
    }

    getCurrentUser():Usuario{
        if (localStorage.getItem(btoa("user"))) {
            return JSON.parse(
                CryptoJS.AES.decrypt(
                    localStorage.getItem(btoa("user")),
                    environment.keys.storage
                ).toString(CryptoJS.enc.Utf8)
            );
        }
        return null;
    }

    serCurrentUser(user:Usuario){
        localStorage.setItem(
            btoa("user"),
            CryptoJS.AES.encrypt(
                JSON.stringify(user),
                environment.keys.storage
            ).toString()
        );
        this.usuarioAtualizado.next(true);
    }

    atualizarToken() {
        const us = this.getCurrentUser();
        return this.http.get(environment.apiUrl + `token/${us.uuid}/atualiza`);
    }

}