import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule)
  }, {
    path: 'acesso',
    loadChildren: () => import('./acesso/access.module').then(m => m.AccessPageModule)
  },
  {
    path: 'ajuda',
    loadChildren: () => import('./testes/ajuda/ajuda.module').then(m => m.AjudaPageModule)
  },
  {
    path: 'permissions',
    loadChildren: () => import('./_components/permissions/permissions.module').then(m => m.PermissionsPageModule)
  },
  {
    path: 'webview',
    loadChildren: () => import('./testes/webview/webview.module').then(m => m.WebviewPageModule)
  },
  { path: 'catalogo/:slug', loadChildren: () => import('./acesso/seleciona-estabelecimento/seleciona-estabelecimento.module').then(m => m.SelecionaEstabelecimentoPageModule) },
  { path: 'cardapio/:slug', loadChildren: () => import('./acesso/seleciona-estabelecimento/seleciona-estabelecimento.module').then(m => m.SelecionaEstabelecimentoPageModule) },
  { path: 'demanda/:slug', loadChildren: () => import('./acesso/seleciona-estabelecimento/seleciona-estabelecimento.module').then(m => m.SelecionaEstabelecimentoPageModule) },
  { path: 'mesa/:tokenMesa', loadChildren: () => import('./acesso/seleciona-estabelecimento/seleciona-estabelecimento.module').then(m => m.SelecionaEstabelecimentoPageModule) },
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: `reload` })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
