<style>
  .box-codigo {
    border-radius: 1em;
    width: 100%;
    padding: 1.5em 1em;
    border-width: 1px;
    font-weight: 500;
    padding: 15px;
    overflow: hidden;
    margin-bottom: 2px;
    font-size: 11px;
    background: #85858529;
    border-radius: 13px;
  }

  .box-codigo p {
    overflow: hidden;
  }

  .bg-success {
    background-color: #f1fbf4 !important;
    border: 1px solid #36bb67;
    color: #36bb67;
    position: relative;
  }
</style>

<div class="" style="min-height: 10em">
  <loading class="mt-5" *ngIf="loading"></loading>

  <div *ngIf="!loading" class="p-4">
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="text-primary">QR Code criado com sucesso</h4>
      <p class="p-1 m-0 text-dark" (click)="fecharModal()"><i class="fal fa-2x fa-times"></i></p>
    </div>

    <p>
      Copie o código e faça o pagamento Pix pelo seu app do banco. <br />
      <br />
    </p>

    <div class="p-4" style="border: 1px solid #eee; border-radius: 18px">
      <div class="w-100 mb-2 d-flex justify-content-center">
        <ngx-qrcode *ngIf="pixCopiaCola" [elementType]="'text'" [value]="pixCopiaCola" cssClass="aclass" errorCorrectionLevel="L"> </ngx-qrcode>
      </div>
      <div class="box-codigo" (click)="copiarCodigo()" [ngClass]="{ 'bg-success': codigoCopiado }">
        <p>
          {{ pixCopiaCola }}
        </p>
      </div>
      <p class="text-center">
        <small></small>
        <small *ngIf="!codigoCopiado">Clique no código para copiar</small>
        <small *ngIf="codigoCopiado">Código copiado!</small>
      </p>
    </div>

    <div class="pt-4 bg-white">
      <div class="row">
        <div class="col">
          <p class="fw-light mb-0">Total</p>
          <h5 class="fw-bold my-0">{{ atendimento?.valorTotal | currency: 'BRL':'symbol-narrow':'1.2-2' }}</h5>
        </div>
        <div class="col-auto">
          <button (click)="copiarCodigo()" *ngIf="!aguardandoConfirmacao" style="background-color: black" class="btn p-3 btn-block btn-lg btn-dark">
            <span>
              <small *ngIf="codigoCopiado">
                <i class="fal fa-check-circle text-warning"></i>
                Código copiado
              </small>
              <small *ngIf="!codigoCopiado"> Copiar código </small>
            </span>
          </button>
          <p class="text-center" *ngIf="aguardandoConfirmacao">
            <i class="fal fa-circle-notch fa-spin fa-2x text-black"></i>
            <br />
            Aguardando pagamento
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
