import { Component, Input, OnInit } from '@angular/core';
import { CarrinhoService } from 'src/app/_services/carrinho.service';

@Component({
  selector: 'app-produto-quantidade',
  template: `<style>
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
<div class="count-number rounded-pill d-flex px-1 flex-row float-right text-white" style="    background-color: #6c757d;">
<button style=" padding: 2px;    font-size: 10px;" class="btn text-white rounded-pill pl-1 btn-sm left dec dec" [disabled]="quantidade===0" (click)="remove()">
<i class="fal fa-minus" *ngIf="quantidade>1"></i>
<i class="fal fa-trash" *ngIf="quantidade==1"></i>
</button>
  <input readonly  (click)="add()" class="count-number-input fw-bold bg-transparent" style="font-size: 11px;text-align: center;  border: none;  width: 1.5em;" type="number" (keyup)="onChange($event)" (keyup.enter)="setQuantity()"
    [(ngModel)]="quantidade">

  <button style=" padding: 2px;   font-size: 10px; " class="btn text-white rounded-pill pr-1 btn-sm left dec inc" (click)="add()">
  <i class="fal fa-plus"></i></button>
</div>`
})
export class ProdutoQuantityComponent implements OnInit {
  @Input() quantidade;
  @Input() index;
  constructor(private CarrinhoService: CarrinhoService) {
  }

  ngOnInit() {
  }

  add() {
    const estabelecimento = JSON.parse(localStorage.getItem('estabelecimento'));

    this.quantidade += 1;
    this.CarrinhoService.modificarQuantidadeCarrinho(this.index, estabelecimento.idEstabelecimento, 'add');
  }

  onChange(tecla) {
    const estabelecimento = JSON.parse(localStorage.getItem('estabelecimento'));

    if (tecla.keyCode >= 48 && tecla.keyCode <= 57) {
      this.setQuantity();
    }
  }
  setQuantity() {
    const estabelecimento = JSON.parse(localStorage.getItem('estabelecimento'));

    this.CarrinhoService.setQuantity(this.quantidade, this.index, estabelecimento.idEstabelecimento);

  }

  remove() {
    const estabelecimento = JSON.parse(localStorage.getItem('estabelecimento'));

    this.quantidade -= 1;
    this.CarrinhoService.modificarQuantidadeCarrinho(this.index, estabelecimento.idEstabelecimento, 'remove');
  }

}


