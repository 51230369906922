// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.



export const environment = {
  production: false,
  apiUrl: 'https://api-dev.cardmenu.com.br/',
  apiHost:'api-dev.cardmenu.com.br',
  // apiUrl: 'http://192.168.1.105:8080/SmartMenuMobileService/',
  // apiHost:'192.168.1.105:8080',
  appUrl: 'https://beta.cardmenu.com.br/',
  siteUrl: 'http://site.cardmenu.com.br',
  facebook: {
    appId: '2229197144019612'
  },
  baseNumberOrder: 15013,
  firebase: {
    apiKey: "AIzaSyACDEqgz_X0eV0zNhQ5eTrdGUS8niVCheE",
    authDomain: "udicarte.firebaseapp.com",
    databaseURL: "https://udicarte.firebaseio.com",
    projectId: "udicarte",
    storageBucket: "udicarte.appspot.com",
    messagingSenderId: "853383689908",
    appId: "1:853383689908:web:91d4d82870ae72bd1109e1",
    measurementId: "G-X48SZK3RN3"
  },
  appInsights: {
    connectionString: 'InstrumentationKey=cdc20eb6-f920-41f5-a28d-60a08c382c9d;IngestionEndpoint=https://westus2-2.in.applicationinsights.azure.com',
    iKey: '8f652d82-def0-453e-9dfb-2e882d8f3b59'
  },
  recaptchaKey: "6LfPJs0UAAAAAOFrMWQqaPRsMQ-n4vlwuIAdyhUe",
  oneSignal: {
    appId: "b919186b-f797-4349-be1d-80171b391a91",
    restApiKey: "NDdmOTk0MTUtMGM0MS00NzM1LWE3ZWEtMjNiY2ExZWY5YTAy"
  },
  version: require('../../package.json').version + '-beta',
  keys: {
    storage: "74b0LOWx",
    metodoDePagamento: '9cardapio7online8qeM3qWTpn7gbQe5'
  },
  defaultLogin: {
    username: "camilopinheiro@live.com",
    password: "123123"
  }
};



