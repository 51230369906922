
export enum TipoPagamentoEnum{
    DINHEIRO="DINHEIRO",
    BOLETO="BOLETO",
    CARTAO_CREDITO="CARTAO_CREDITO",
    CARTAO_DEBITO="CARTAO_DEBITO",
    PIX="PIX",
    PAGAMENTO_ONLINE="PAGAMENTO_ONLINE"
  }
  
  export enum TipoAtendimentoEnum{
    DEMANDA="DEMANDA",
    COMANDA="COMANDA",
    DELIVERY="DELIVERY"
  }
  
  export enum StatusAtendimentoEnum {
    A_VALIDAR = "A_VALIDAR",
    ABERTO = "ABERTO",
    AGUARDANDO_PAGAMENTO = "AGUARDANDO_PAGAMENTO",
    AGUARDANDO_ENTREGA = "AGUARDANDO_ENTREGA",
    PAGO = "PAGO",
    CONCLUIDO = "CONCLUIDO",
    CANCELADO = "CANCELADO"
  }

  export enum TipoVerificacaoMinimoMaximoEnum{
    POR_COMANDA="POR_COMANDA",
    POR_PEDIDO="POR_PEDIDO"
  }

  export enum StatusCobrancaEnum {
    ANDAMENTO = "ANDAMENTO",
    CANCELADO = "CANCELADO",
    CONCLUIDO = "CONCLUIDO"
  }

  export enum StatusPedidoEnum {
    ABERTO = "ABERTO",
    EM_PREPARO = "EM_PREPARO",
    PRONTO_PARA_RETIRAR = "PRONTO_PARA_RETIRAR",
    CANCELADO = "CANCELADO",
    FINALIZADO_ENTREGUE = "FINALIZADO_ENTREGUE"
  }


  
export enum TipoCartaoEnum {
	'DEBITO' = 'DEBITO',
	'CREDITO' = 'CREDITO'
}