<ion-header class="ion-no-border">
  <ion-toolbar class="border-none py-2">
    <ion-buttons slot="end">
      <ion-button (click)="fecharModal()" class="p-0">
        <i class="fal fa-times" style="color:#000; font-size: 1.2em;"></i>
      </ion-button>
    </ion-buttons>
    <ion-title style="font-size: 1.5em!important">Novo endereço</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div id="inicio" [hidden]="exibir" class="px-3 py-4 animate__animated animate__faster animate__fadeIn">
    <ion-searchbar (click)="mudarTela('list')" placeholder="Informe seu endereço e número"></ion-searchbar>
    <ion-list class="mt-4">
      <ion-item lines="none" (click)="mudarTela('mapa')">
        <ion-avatar style="display: flex; align-items: center" class="m-0 text-center" slot="start">
          <i class="fal text-danger fa-map-marker"></i>
        </ion-avatar>
        <ion-label>
          <b>Usar sua localização atual</b>
          <p class="span text-muted">{{enderecoFormatado || 'carregando...'}}</p>
        </ion-label>
      </ion-item>
    </ion-list>
  </div>
  <div id="list" class="animate__animated animate__faster animate__fadeIn" [hidden]="exibir!='list'">
    <div class="p-1">
      <ion-icon size="large" name="chevron-back-outline" (click)="mudarTela(null)"></ion-icon>
    </div>
    <ion-searchbar
      [(ngModel)]="autocomplete.input"
      #autofocus
      class="mt-2"
      style="font-size: 3px"
      (ionInput)="UpdateSearchResults()"
      placeholder="Pesquisar endereço"
      (ionClear)="ClearAutocomplete()"
    ></ion-searchbar>
    <ion-list [hidden]="autocompleteItems.length == 0">
      <ion-item *ngFor="let item of autocompleteItems" class="py-2" (click)="SelectSearchResult(item)"> {{ item.description }} </ion-item>
    </ion-list>
  </div>
  <div id="mapa" [hidden]="exibir!='mapa'" class="map-wrapper animate__animated animate__faster animate__fadeIn" hidden style="height: 65vh">
    <div class="w-100">
      <div *ngIf="endereco?.localidade" class="py-3 bg-danger text-white text-center">
        <div>
          <div style="position: absolute; left: 0; z-index: 10000" (click)="mudarTela(null)">
            <ion-icon size="large" name="chevron-back-outline"></ion-icon>
          </div>
          <div class="col text-center">
            <b *ngIf="endereco?.logradouro || endereco?.numeroLogradouro " style="font-size: 1.3em; overflow: show">
              {{ endereco?.logradouro + ' '+ endereco?.numeroLogradouro }}
            </b>
            <br />
            <span> {{ endereco?.bairro + ' '+ endereco?.localidade + ' '+ endereco?.siglaEstado }} </span>
          </div>
        </div>
      </div>
    </div>
    <div class="p-1">
      <div id="map_center">
        <ion-icon name="location" size="large" color="danger"></ion-icon>
      </div>
      <div #map id="map" style="height: 60vh"></div>
    </div>
  </div>
  <div id="confirmar" style="height: 80%" class="card-body animate__animated animate__faster animate__fadeIn v-100" [hidden]="exibir!='confirmar'">
    <ion-icon size="large" name="chevron-back-outline" (click)="mudarTela('mapa')"></ion-icon>
    <br />
    <br />
    <h4 class="text-danger">Concluir cadastro</h4>
    <div class="row">
      <div class="col">
        <div class="row mb-2">
          <div class="col">
            <span> Rua:</span>
            <input [(ngModel)]="endereco.logradouro" type="text" class="form-control" />
          </div>
        </div>
        <div class="row mt-3 g-0">
          <div class="col-5 pr-2">
            <span> Bairro:</span>
            <input [(ngModel)]="endereco.bairro" type="text" class="form-control" />
          </div>
          <div class="col-2 pr-2">
            <span> Estado:</span>
            <input [(ngModel)]="endereco.uf" type="text" class="form-control" />
          </div>
          <div class="col-5">
            <span> Cidade:</span>
            <input [(ngModel)]="endereco.localidade" type="text" class="form-control" />
          </div>
        </div>
        <div class="row g-0 mt-3">
          <div class="col-3 pr-2"><span> N°: </span><input type="tel" [(ngModel)]="endereco.numeroLogradouro" name="" class="form-control" /></div>
          <div class="col">
            <span>Complemento:</span> <input type="text" placeholder="Ex.: apto 301 Bloco 02" [(ngModel)]="endereco.complemento" name="" class="form-control" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <span> Nome do local:</span> <input type="text" name="" [(ngModel)]="endereco.nomeEndereco" placeholder="Ex.: Casa, traballho" class="form-control" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
<ion-footer>    
  <ion-button [hidden]="exibir!='mapa'"  (click)="mudarTela('confirmar')" color="danger" expand="block">
    <h5 class="m-0">Confirmar &nbsp; <i class="fal fa-map-marker-alt"></i></h5>
  </ion-button>
  <ion-button [hidden]="exibir" (click)="mudarTela('mapa')" color="danger" expand="block">
    <h5 class="m-0">Buscar no mapa &nbsp; <i class="far fa-map"></i></h5>
  </ion-button>
  <ion-button [hidden]="exibir!='confirmar'" (click)="salvarEndereco()" color="danger" expand="block">
    <h5 class="m-0">Cadastrar endereço &nbsp; <i class="far fa-check"></i></h5>
  </ion-button>
</ion-footer>
















<script async src="https://maps.googleapis.com/maps/api/js?key=AIzaSyB6QgBvFSz8Zy_-4YvsGsOpA3IhnVJMZEA&callback=initAutocomplete&libraries=places&v=weekly"></script>
