import { ItemCarrinho } from './itemCarrinho';

export class Atendimento {
  idAtendente?: number;
  idAtendimento?: number;
  idCartao?: number;
  idEstabelecimento?: number;
  idMesa?: number;
  idEndereco?: number;
  idMetodoPagamento?: number;
  ip?: string;
  listProdutoEntity?: [ItemCarrinho];
  obs?: string;
  pedidoAberto?: false;
  enderecoEntity?: any = {};
  possuiAgregado?: false;
  statusPedido?: string;
  tipoAtendimentoEnum?: tipoAtendimentoEnum;
  tipoEntregaEnum?: 'ENTREGA' | 'RETIRA_NA_LOJA';
  troco?: number;
  pagamentoNaEntrega?: boolean;
  valor?: number;
  valorTotal?: number;
  necessitaValidacao?: any;
  cupom?: string;
  metodoPagamento?: any;
  hashatendimento?: any;
  nomePessoa?: string;
  telefonePessoa?: string;
  cpfPessoa?: string;
  tipoProdutoEnum?: tipoProdutoEnum;
  hashAtendimento?: string;
  dataAtendimentoInicialFormat?:string;
  dataAtendimentoFinalFormat?:string;

  //template atributo
  novasMensagens?:number;
}

export enum ordemCardapioEnum {
  "ALFABETICO" = "ALFABETICO",
  "MAIOR_VALOR" = "MAIOR_VALOR",
  "MENOR_VALOR" = "MENOR_VALOR",
}

export enum tipoProdutoEnum {
  "NORMAL" = "NORMAL",
  "VARIOS_SABORES_MAIS_CARO" = "VARIOS_SABORES_MAIS_CARO"
}


export enum tipoAtendimentoEnum {
  'DELIVERY' = 'DELIVERY',
  'DEMANDA' = 'DEMANDA',
  'COMANDA' = 'COMANDA'
}