import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModalPixComponent } from '../_components/modal-pix/modal-pix.component';
import { firestoreService } from './firestore.service';
import { Cartao, CartaoType } from '../_models/Cartao';

@Injectable({
  providedIn: 'root'
})
export class PagamentoService {
  evaluates: any;
  constructor(
    private fs: firestoreService,
    private http: HttpClient,
    private modalController: ModalController,
  ) {
  }

  getCartoes():Observable<Array<CartaoType>>{
    return this.http.get<Array<CartaoType>>(environment.apiUrl+'cartoes');
  }

  detalharCartao(idCartao):Observable<CartaoType>{
    return this.http.get<CartaoType>(environment.apiUrl+'cartoes/'+idCartao);
  }

  salvarCartao(cartao:Cartao) {
    return this.http.post(environment.apiUrl+'cartoes', cartao);
  }

  alterarCartao(cartao:Cartao) {
    return this.http.post(environment.apiUrl+'cartoes/', cartao);
  }

  removerCartao(idCartao:number) {
    return this.http.delete(environment.apiUrl+'cartoes/'+idCartao);
  }

  gerarCobrancaFirebase(idAtendimento, idEstabelecimento, idUsuario) {
    const obj: any = { idEstabelecimento: idEstabelecimento, status: 'ABERTO', idUsuario: idUsuario, idAtendimento: idAtendimento };
    for (var k in obj)
      if (!obj[k]) delete obj[k];
    this.fs.set('/pagamentos/' + idAtendimento, obj);
  }

  abrirModalPix(atendimento, pixCopiaCola) {
    return new Promise(async (res, rej) => {
      const modal = await this.modalController.create({
        cssClass: ' swipe-modal auto-height',
        swipeToClose: false, keyboardClose: false, showBackdrop: true, componentProps:
          { 'atendimento': atendimento, pixCopiaCola: pixCopiaCola }, backdropDismiss: false,
        component: ModalPixComponent
      });
      await modal.present();
      modal.onDidDismiss().then((d: any) => {
        if (d?.data?.status)
          res(d.data);
        else
          rej(d.data);
      });
    });
  }


}
