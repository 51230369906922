import { Router } from '@angular/router';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import * as Sentry from "@sentry/browser";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) { }
  handleError(error) {
    const router = this.injector.get(Router);
  }

}