<div class="p-3">
  <ion-item lines="none">
    <ion-label class="ion-text-wrap">
      <h4 class="fw-bolder">Precisamos validar o atendimento n° {{ idAtendimento }}</h4>
    </ion-label>
  </ion-item>
  <div class="card mt-2 bg-light">
    <ion-item color="none" lines="none">
      <ion-label class="ion-text-wrap">
        <p class="text-center">
          <b class="text-dark">Mostre este código qr ao garçom</b>
        </p>
      </ion-label>
    </ion-item>

    <div class="justify-content-center d-flex pb-3">
      <ngx-qrcode *ngIf="idAtendimento" [elementType]="'url'" [value]="idAtendimento + ''" cssClass="aclass" errorCorrectionLevel="L"> </ngx-qrcode>
    </div>
  </div>
  <div class="casrd pt-2 mt-2 pb-4 px-1 bg-white">
    <ion-item color="none" lines="none" class="mb-n2">
      <small>Peça ao garçom para te informar o código do atendimento: {{ idAtendimento }} e informe-o abaixo</small>
    </ion-item>
    <ion-item color="none">
      <ion-label position="floating"> Código do garçom </ion-label>
      <ion-input type="tel" maxlength="5" size="8" [(ngModel)]="hashAtendimento"></ion-input>
    </ion-item>
    <ion-item color="none" lines="none">
      <div class="w-100 pt-2 justify-content-center d-flex flex-column">
        <button (click)="validar()" [disabled]="!hashAtendimento?.length" [ngClass]="{ 'btn-block animate__animated': !loading }" class="btn btn-warning p-3 mt-3 btn-sm">
          <ion-spinner color="light" *ngIf="loading" name="crescent"></ion-spinner>
          <div class="d-flex align-items-center justify-content-center" *ngIf="!loading">
            Concluir&nbsp;
            <i class="fal pt-1 fa-long-arrow-right"></i>
          </div>
        </button>
      </div>
    </ion-item>
  </div>

  <br />
  <br />
  <br />
  <br />
</div>
