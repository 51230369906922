import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from "@sentry/browser";
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { IonicInputMaskModule } from '@thiagoprz/ionic-input-mask';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { IonicHeaderParallaxModule } from 'ionic-header-parallax';
import { NgOtpInputModule } from 'ng-otp-input';
import { LottieModule } from 'ngx-lottie';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import "./firebase-initialization";
import { SharedModule } from './shared.module';
import { customTransition } from './_animations/nav.animation';
import { SplashScreenComponent } from './_components/splashscreen.component';
import { GlobalErrorHandler } from './_helpers/global-error-handler';
import { RequestInterceptor } from './_helpers/requestinterceptor';
import { MessagingService } from './_services/messaging.service';
import { NgxMaskModule, IConfig } from 'ngx-mask';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error) {
    Sentry.captureException(error.originalError || error);
  }
}

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [AppComponent, SplashScreenComponent],
  imports: [BrowserModule, ZXingScannerModule,
    BrowserAnimationsModule,
    FormsModule,
    SharedModule,
    NgxQRCodeModule,
    IonicHeaderParallaxModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    IonicInputMaskModule,
    NgOtpInputModule,
    LottieModule.forRoot({ player: playerFactory, useWebWorker: true }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AngularFireMessagingModule,
    IonicModule.forRoot({
      scrollAssist: false,
      navAnimation: customTransition,
      rippleEffect: true,
      backButtonText: '',
      mode: 'ios'
    }), AppRoutingModule,
    ServiceWorkerModule.register('combined-sw.js', { enabled: environment.production }),
    NgxMaskModule.forRoot(),
  ],
  providers: [
    StatusBar,
    InAppBrowser,
    MessagingService,
    StatusBar,
    SplashScreen,
    Geolocation,
    NativeGeocoder,
    SplashScreen,
    {provide: ErrorHandler,useClass: GlobalErrorHandler},
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent], schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule { }


export function playerFactory() {
  return import('lottie-web');
}


Sentry.init({
  dsn: 'https://fb57a2ba0b324db391ad32a6d396125e@o972309.ingest.sentry.io/5924563',
  release: environment.version,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: false
    })
  ],
  environment: environment.production ? 'prod' : 'dev',
});

registerLocaleData(localePt, "pt-br");

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
