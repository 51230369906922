import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { remove } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { ItemCarrinho } from '../_models/itemCarrinho';

@Injectable({
  providedIn: 'root'
})
export class CarrinhoService {
  private Carrinho;

  constructor(
    private router: Router, 
    public toastController: ToastController) {
  }

  async presentToast(msg, showCarrinho?) {
    const toastOpts = {
      message: msg,
      color: 'success',
      dismissOnPageChange: true,
      buttons: [],
      animated: true,
      duration: (showCarrinho) ? 1500 : 800
    };

    if (showCarrinho)
      toastOpts.buttons = [{
        text: 'Ver carrinho',
        role: 'cancel',
        handler: () => this.router.navigate(['/carrinho'])
      }];
    const toast = await this.toastController.create({ ...toastOpts, position: 'top' })
    toast.present();
  }


  addToCarrinho(produtoAdicionado, idEstabelecimento, showCarrinho?) {
    produtoAdicionado = JSON.parse(JSON.stringify(produtoAdicionado));

    const itemAdicionado: ItemCarrinho = {
      indice: +new Date + '',
      nomeProduto: produtoAdicionado.produto,
      idProduto: produtoAdicionado.idProduto,
      linkImagem: produtoAdicionado.linkImagem,
      icon: produtoAdicionado.icon,
      precoProduto: produtoAdicionado.valor,
      listPassosProdutoAgregadoEntity: produtoAdicionado?.listPassosProdutoAgregadoEntity
        ?.map(ppae => {
          ppae.listProdutoAgregadosEntity = ppae.listProdutoAgregadosEntity?.filter(pae => pae.quantidade);
          return ppae;
        })?.filter(ppae => {
          return ppae?.listProdutoAgregadosEntity?.length > 0
        }),
      observacao: produtoAdicionado.observacao,
      quantidade: 1,
      idEstabelecimento: idEstabelecimento
    };
    if (localStorage.getItem('Carrinho') == null) {
      const Carrinho: any = [];
      Carrinho.push(JSON.stringify(itemAdicionado));
      this.presentToast(produtoAdicionado.produto + ' foi adicionado ao carrinho', showCarrinho);
      localStorage.setItem('Carrinho', JSON.stringify(Carrinho));
    } else {
      const Carrinho: any = JSON.parse(localStorage.getItem('Carrinho'));
      let indice = -1;
      for (let i = 0; i < Carrinho.length; i++) {
        const itemDoCarrinho: ItemCarrinho = JSON.parse(Carrinho[i]);
        if (itemDoCarrinho?.idProduto === itemAdicionado?.idProduto) {
          const passosDoCarrinho = itemDoCarrinho.listPassosProdutoAgregadoEntity?.map(trataPassos);
          const passosDoNovoItem = itemAdicionado.listPassosProdutoAgregadoEntity?.map(trataPassos);
          function trataPassos(p) {
            p.listProdutoAgregadosEntity = p.listProdutoAgregadosEntity.filter(r => r.quantidade)
            return p;
          }
          const passosRepetem = this.compararArrays(passosDoCarrinho?.map(a => a.idPassosProduto), passosDoNovoItem?.map(a => a.idPassosProduto))
          if (passosRepetem) {
            const idsRepetem = passosDoCarrinho?.map(passo => this.compararArrays(passosDoNovoItem.find(pa => pa.idPassosProduto == passo.idPassosProduto).listProdutoAgregadosEntity?.map(v => v.idProduto), passo.listProdutoAgregadosEntity?.map(v => v.idProduto)))?.every(r => r);
            if (idsRepetem) {
              passosDoCarrinho?.map(passo => {
                const agregadosPassoCarrinho = passo?.listProdutoAgregadosEntity;
                const agregadosPassoNovoProduto = passosDoNovoItem.find(pa => pa.idPassosProduto == passo.idPassosProduto)?.listProdutoAgregadosEntity;
                passo.quantidadesBatem = agregadosPassoCarrinho?.map(agrC => agrC.quantidade == agregadosPassoNovoProduto.find(agrP => agrC.idProduto == agrP.idProduto).quantidade)?.every(r => r);
                return passo;
              });
              if (passosDoCarrinho?.every(a => a.quantidadesBatem))
                if (itemDoCarrinho?.observacao == itemAdicionado?.observacao)
                  indice = i;
            }
          }
          if (itemDoCarrinho?.observacao != itemAdicionado?.observacao) indice = -1;
        }
      }
      if (indice === -1) {
        this.presentToast(itemAdicionado?.nomeProduto + ' foi adicionado ao carrinho', showCarrinho);
        Carrinho.push(JSON.stringify(itemAdicionado));
        localStorage.setItem('Carrinho', JSON.stringify(Carrinho));
      } else {
        const item: ItemCarrinho = JSON.parse(Carrinho[indice]);
        item.quantidade += 1;
        Carrinho[indice] = JSON.stringify(item);
        localStorage.setItem('Carrinho', JSON.stringify(Carrinho));
        this.presentToast('Carrinho atualizado', showCarrinho);
      }
    }
    this.Carrinho.next(this.loadCarrinho(idEstabelecimento));
  }

  compararArrays(arr1, arr2) {
    const repete = arr1?.length === arr2?.length && arr1?.every((value, index) => value === arr2[index])

    return arr1?.length === arr2?.length && arr1?.every((value, index) => value === arr2[index]);
  }


  modificarQuantidadeCarrinho(indice, idEstabelecimento, operacao, showCarrinho?) {
    const Carrinho: any = JSON.parse(localStorage.getItem('Carrinho'));
    const newCarrinho = [];
    Carrinho?.map(res => {
      res = JSON.parse(res);
      if (res.indice === indice) {
        if (operacao == 'remove') {
          if (res.quantidade === 1)
            remove(indice, idEstabelecimento);
          res.quantidade--;
        } else
          res.quantidade++;
      }
      if (res.quantidade > 0)
        newCarrinho.push(JSON.stringify(res));
    });
    localStorage.setItem('Carrinho', JSON.stringify(newCarrinho));
    this.loadCarrinho(idEstabelecimento);
    this.presentToast('Carrinho atualizado', showCarrinho);
    this.Carrinho.next(this.loadCarrinho(idEstabelecimento));
  }

  cleanCarrinho(idEstabelecimento) {
    //    if (!environment.production) return;
    localStorage.removeItem('Carrinho');
    this.Carrinho.next(this.loadCarrinho(idEstabelecimento));
  }

  remove(id: string, idEstabelecimento?, showCarrinho?): void {
    const Carrinho: any = JSON.parse(localStorage.getItem('Carrinho'));
    const indice = -1;
    for (let i = 0; i < Carrinho.length; i++) {
      const item: ItemCarrinho = JSON.parse(Carrinho[i]);
      if (item.indice === id) {
        Carrinho.splice(i, 1);
        break;
      }
    }
    localStorage.setItem('Carrinho', JSON.stringify(Carrinho));
    this.presentToast('Produto removido do carrinho', showCarrinho);
    this.Carrinho.next(this.loadCarrinho(idEstabelecimento));
  }

  setQuantity(quantidade, id, idEstabelecimento) {
    const Carrinho: any = JSON.parse(localStorage.getItem('Carrinho'));
    let indice = -1;
    for (let i = 0; i < Carrinho.length; i++) {
      const item: ItemCarrinho = JSON.parse(Carrinho[i]);
      if (item.indice === id) {
        indice = i;
        break;
      }
    }
    if (indice !== -1) {
      const item: ItemCarrinho = JSON.parse(Carrinho[indice]);
      item.quantidade = quantidade;
      Carrinho[indice] = JSON.stringify(item);
      localStorage.setItem('Carrinho', JSON.stringify(Carrinho));
    }
    this.Carrinho.next(this.loadCarrinho(idEstabelecimento));
  }


  loadCarrinho(idEstabelecimento) {
    let precoAdicionais = 0;
    const myCarrinho = { valor: 0, idEstabelecimento: idEstabelecimento, valorTotal: 0, listProdutoEntity: [] };
    const Carrinho = JSON.parse(localStorage.getItem('Carrinho')) || [];
    for (let i = 0; i < Carrinho.length; i++) {
      const item = JSON.parse(Carrinho[i]);
      if (item.idEstabelecimento === myCarrinho.idEstabelecimento) {
        if (item.quantidade > 0) {
          precoAdicionais = 0;
          item.listPassosProdutoAgregadoEntity?.map(res => {
            precoAdicionais += res.listProdutoAgregadosEntity.reduce(function (acc, obj) { return acc + Number(obj.precoProduto) * Number(obj.quantidade); }, 0)
          });
          item.precoProduto += precoAdicionais;
          item.precoProdutoSemAdicionais = item.precoProduto - precoAdicionais;
          myCarrinho.valor += (item?.precoProduto * item?.quantidade);
          item.precoAdicionais = precoAdicionais;
          myCarrinho.listProdutoEntity.push({
            ...item,
            idEstabelecimento: myCarrinho.idEstabelecimento
          });
        }
      }
      myCarrinho.valorTotal = myCarrinho.valor;
    }
    return myCarrinho;
  }

  getCarrinho(id): Observable<any> {
    this.Carrinho = new BehaviorSubject<any>(this.loadCarrinho(id));
    return this.Carrinho.asObservable();
  }

  checkAddits(a, b) {
    if (a === b) { return true; }
    if (a == null || b == null) { return false; }
    if (a.length !== b.length) { return false; }

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) { return false; }
    }
    return true;
  }
}
