import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor, Plugins } from '@capacitor/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from "@sentry/browser";
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { Usuario } from './_models/Usuario';
import { firestoreService } from './_services/firestore.service';
import { MessagingService } from './_services/messaging.service';
import { UserService } from './_services/user.service';
const { LocalNotifications } = Plugins;


@Component({
  selector: 'app-root',

  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  deferredPrompt: any;
  versaoObsoleta;
  message;
  appInstalled;
  prod = environment.production;
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  showInstallMessage: any;
  usuario:Usuario;

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    e.preventDefault();
  }

  showSplash = true;
  constructor(private platform: Platform, private us: UserService, public alertController: AlertController,
    private fs: firestoreService,
    private splashScreen: SplashScreen,
    private router: Router,
    private ms: MessagingService, private translate: TranslateService, private statusBar: StatusBar) {
    this.initializeApp();
    moment.locale("pt-br");

  }

  updateVersion() {
    this.fs.doc$('/infrastructure/app').subscribe((r: any) => {
      this.versaoObsoleta = localStorage.getItem('version') != r.version && environment.production;
      delete r.createdAt;
      delete r.updatedAt;
      r[localStorage.getItem('version')] = r[localStorage.getItem('version')] || 0 + 1;
      //  if (localStorage.getItem('version'))
      //     this.fs.update('/infrastructure/app/', r)

    });
    return
    if (!environment.production)
      if (!(localStorage.getItem('version') == environment.version)) {
        localStorage.clear();
        location.reload();
      }
    localStorage.setItem('version', environment.version + "");
  }


  removeCache() {
    window.location.href = window.location.href;
    window.location.reload();
  }

  async check() {
    if (!environment.production) return;
    const response: {
      status: string
    } = await Capacitor.Plugins.CAMERA.requestPermission({
      permission: "CAMERA"
    });
  }

  addToHomeScreenDiv() {
    if (!this.deferredPrompt) return;
    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          //  console.log('User accepted the A2HS prompt');
        } else {
          //  console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
  }
  async initializeApp() {
    this.platform.ready().then(() => {
      this.platform.backButton.subscribeWithPriority(1, () => { }); // to disable hardware back button on whole app
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(true);
      this.statusBar.backgroundColorByHexString('#457492');
      this.splashScreen.hide();
      this.usuario = this.us.getCurrentUser();
      setTimeout(() => {
        this.splashScreen.hide();
      }, 2000);
      this.statusBar.styleDefault();
    });


    this.updateVersion();
    setTimeout(() => {
      this.showSplash = false;
    }, 3000);
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
      }
      const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator['standalone']);;
      if (isIos() && !isInStandaloneMode()) this.showInstallMessage = true;
      localStorage.setItem('isIos', String(isIos()));
      localStorage.setItem('isInStandaloneMode', String(isInStandaloneMode()));
    }
    this.translate.setDefaultLang(localStorage.getItem('defaultLanguage') || 'pt');
    if (this.usuario) {
      this.ms.requestPermission({ uuid: this.usuario.uuid, idUsuario: this.usuario.idUsuario || 15 });
      this.ms.receiveMessage();
      this.message = this.ms.currentMessage
    }
    const jaPassouPrimeiroAcesso = localStorage.getItem('jaPassouPrimeiroAcesso');
    //if (!jaPassouPrimeiroAcesso) this.router.navigate(['access/onboarding']);
    //if (this.as.estaLogado()) this.router.navigate(['inicio']);
    //   if (!this.as.estaLogado()) this.router.navigate(['access']);
    if (!window.matchMedia('(display-mode: standalone)').matches) {
      setTimeout(() => {
        let el: HTMLElement = this.myDiv.nativeElement;
        el.click();
      }, 5000);
    }

    //if (environment.production)
    Sentry.configureScope((scope) => {
      scope.setUser({
        'id': this.usuario?.idUsuario.toString(),
        'username': this.usuario?.nome,
        'email': this.usuario?.email
      });
      scope.setTag('version', environment.version);
      scope.setTag('isIos', localStorage.getItem('isIos'));
      scope.setTag('isInStandaloneMode', localStorage.getItem('isInStandaloneMode'));
    });

    return;
    const notifs = await LocalNotifications.schedule({
      notifications: [
        {
          title: "Title",
          body: "Body",
          id: 1,
          schedule: { at: new Date(Date.now() + 1000 * 5) },
          sound: null,
          attachments: null,
          actionTypeId: "",
          extra: null
        }
      ]
    });
  }
}
