<ion-header class="ion-no-border ion-no-shadow">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="fecharModal()" class="p-0">
        <i class="fal fa-times" style="color:#000; font-size: 1.2em;"></i>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-no-padding" style="font-size: 1.5em !important">Adicionar Cartão</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content (ionScroll)="handleScroll($event)">
  <ion-grid class="ion-no-padding content-container">
    <form [formGroup]="creditCardForm" #formDirective="ngForm">
      <ion-row>
        <ion-col size-xl="6" offset-xl="3">
          <ion-item lines="none">
            <ion-label position="stacked" class="float-left">Número do cartão
            </ion-label>
            <ion-input
              type="tel"
              #numeroInput
              autofocus="true"
              formControlName="cardNumber"
              class="uppercase ion-shadow border-bottom"
              clearInput
              maxlength="19"
              id="creditNum"
              placeholder="1234 5678 0987 1234"
              (ionChange)="checkCardNumber($event)"
              [class.border-bottom]="!cardValidator.cardNumber || (cardNumber.invalid && cardNumber.touched)"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-xl="6" offset-xl="3">
          <ion-item lines="none">
            <ion-label position="stacked" class="float-left" >Nome</ion-label>
            <ion-input
              type="text"
              formControlName="cardHolderName"
              autocapitalize=words
              class="uppercase"
              clearInput
              placeholder="Gabriela Lima"
              [class.border-bottom]="cardHolderName.invalid && cardHolderName.touched"
              ></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-xl="6" offset-xl="3">
          <ion-item lines="none">
            <ion-label position="stacked" class="float-left" >CPF</ion-label>
            <ion-input
              type="tel"
              formControlName="cardCPF"
              autocapitalize=words
              class="uppercase"
              maxlength="14"
              clearInput
              placeholder=""
              [class.border-bottom]="cardCPF.invalid && cardCPF.touched"
              (ionChange)="atualizaMaskCpf($event)"
              ></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6">
          <ion-item lines="none">
            <ion-label position="stacked" class="float-left" >Validade</ion-label>
            <ion-input
              type="tel"
              formControlName="ccExp"
              class="uppercase"
              clearInput
              maxlength="5"
              placeholder="08/29"
              id="expDate"
              (ionChange)="checkCardExpDate($event)"
              [class.border-bottom]="!cardValidator.expDate || (ccExp.invalid && ccExp.touched)"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item lines="none">
            <ion-label position="stacked" class="float-left" >Código de segurança
            </ion-label>
            <ion-input
              type="tel"
              formControlName="cardCVV"
              autocapitalize=words
              class="uppercase"
              maxlength="3"
              clearInput
              placeholder="CVV"
              (ionChange)="checkcvv($event)"
              [class.border-bottom]="!cardValidator.cVV || (cardCVV.invalid && cardCVV.touched)"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-xl="6" offset-xl="3">
          <ion-item lines="none">
            <ion-checkbox color="success" formControlName="isPrincipal"></ion-checkbox>
            <ion-label class="ion-padding-horizontal">Cartão Principal</ion-label>
          </ion-item>
        </ion-col>
      </ion-row>
    </form>
  </ion-grid>
</ion-content>
<ion-footer>
  <ion-button (click)="onSubmitCard(formDirective)" expand="block"
  color="dark"
  [disabled]="!creditCardForm.valid || !cardValidator.cardNumber || !cardValidator.cVV || !cardValidator.expDate || creditCardForm.invalid">
  Salvar</ion-button>
</ion-footer>