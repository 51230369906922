import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {

  enableProdMode();
  window.console.log = function () { };   // disable any console.log debugging statements in production mode

}


if (!environment.production)
  if (!(localStorage.getItem('version') == environment.version)) {
    localStorage.clear();
    location.reload();
  }
localStorage.setItem('version', environment.version + "");

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

defineCustomElements(window);

