import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, ModalController, NavController } from '@ionic/angular';
import { LoginComponent } from '../acesso/login/login.component';
import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'header-commom',
  template: `
  <div class="w-100 px-4 py-3 mt-2 mb-4 {{cssClass}} " [ngClass]="{'bg-white': !cssClass}">
  <div class="row d-flex justify-content-center pt-2">
  <div class="col-1 d-flex align-items-center" style="font-size: 1.2em !important;">
    <ion-back-button  defaultHref="/" color="dark">
    </ion-back-button>
  </div>
   
  <div class="col d-flex align-items-center ">
    <h4 class=" m-0 fw-bolder">{{title}}</h4>
    <i *ngIf="icon" class="{{icon}}"></i>
  </div>
  
</div>
</div>
`,
})
export class HeaderCommomComponent implements OnInit {
  @Input() title;
  @Input() cssClass;
  @Input() backRoute;
  @Input() hideMenu;
  @Input() icon;

  loading: boolean;
  user: any;
  userAlias: any;
  version: any;
  modal: any;
  constructor(private nc: NavController, private us: UserService, private router: Router, private mc: MenuController, public as: AuthenticationService, private modalController: ModalController) { }

  ngOnInit() {
    this.user = this.us.getCurrentUser();
  }


  voltar() {
    this.nc.navigateBack([this.backRoute || 'cardapio']);
  }

  navegarParaConta() {
    if (!this.as.estaLogado()) {
      this.nc.navigateForward('/access?returnUrl=' + this.router.url).then();
    }
    else this.mc.open();
  }

  pop() {
    this.nc.pop();
  }

  ionViewWillLeave() {
    if (this.modal) this.modal.dismiss();
  }

  async presentModal() {
    this.modal = await this.modalController.create({
      component: LoginComponent,
      swipeToClose: true,
      keyboardClose: true,
      showBackdrop: true,
      mode: 'ios',
      backdropDismiss: true,
      cssClass: 'swipe-modal'
    });
    //this.modal.onDidDismiss().then((d: any) => this.navegarParaConta());

    return await this.modal.present();
  }

}
