import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { firestoreService } from 'src/app/_services/firestore.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-modal-pix',
  templateUrl: './modal-pix.component.html',
})
export class ModalPixComponent implements OnInit {
  @Input() atendimento;
  @Input() pixCopiaCola = '';
  loading;
  codigoCopiado;
  aguardandoConfirmacao;

  constructor(
    private modalController: ModalController,
    private ss: SharedService,
    private toast: ToastController,
    private fs: firestoreService,
  ) { }

  ngOnInit() {
    this.fs.doc$('/ATENDIMENTOS/' + this.atendimento.idAtendimento).subscribe((r: any) => {
      if (r?.statusPagamento?.status == 'CONCLUIDO') {
        // this.modalController.dismiss({ status: 'PAGO' });
      }
    })
  }

  async copiarCodigo() {
    var input = document.createElement('input');
    input.setAttribute('value', this.pixCopiaCola);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    const toast = await this.toast.create({
      message: 'Código copiado com sucesso',
      buttons: [],
      color: 'success',
      animated: true,
      duration: 4000
      , position: 'bottom'
    });

    setTimeout(() => {
      this.aguardandoConfirmacao = true;
    }, 5000);

    this.codigoCopiado = false;
    setTimeout(() => {
      toast.present()
      this.codigoCopiado = true
    }, 10);
    const a = (() => {
    }).bind(this)
    a();
  }

  fecharModal() {
    this.modalController.dismiss({status:'AGUARDANDO_PAGAMENTO'});
  }

}
