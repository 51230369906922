import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-revisar-pedido',
  templateUrl: './modal-revisar-pedido.component.html',
})
export class ModalRevisarPedidoComponent implements OnInit {
  @Input() carrinho;
  @Input() atendimento;
  constructor(private modalController: ModalController) { }

  ngOnInit() { }

  sair() {
    this.modalController.dismiss(false);
  }

  confirmar() {
    this.modalController.dismiss(true);
  }

}
