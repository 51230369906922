import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { IonInput, LoadingController, ModalController } from '@ionic/angular';
import Cleave from 'cleave.js';
import CryptoJS from "crypto-js";
import { MaskPipe } from 'ngx-mask';
import * as payform from 'payform';
import { TipoCartaoEnum } from 'src/app/_enums/Enum';
import { Cartao } from 'src/app/_models/Cartao';
import { PagamentoService } from 'src/app/_services/pagamento.service';
import { SharedService } from 'src/app/_services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-manter-cartao',
  templateUrl: './manter-cartao.page.html',
  styleUrls: ['./manter-cartao.page.scss'],
})
export class ManterCartaoPage implements OnInit {
  creditCard = 'https://raw.githubusercontent.com/anahvictoronyedic/ionic-credit-card-app/master/src/assets/images/card.png';
  creditCardForm: FormGroup;
  isScrolled = false;

  anos = [...Array(15)].map((a, b) => new Date().getFullYear() + b + "");
  Cartao = new Cartao();
  tipoCartao = TipoCartaoEnum;

  @ViewChild('numeroInput', { static: true }) numeroInput: IonInput;

  cardValidator = {
    cardNumber: true,
    cardName: true,
    expDate: true,
    cVV: true,
    cPF: true,
  };

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private ps: PagamentoService, 
    private loadingController: LoadingController,
    private maskPipe:MaskPipe,
    private sharedService:SharedService
  ) { }


  ngOnInit() {
    this.creditCardForm = this.fb.group({
      cardNumber: new FormControl((!environment.production) ? '5159962632880612' : null, {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      cardHolderName: new FormControl((!environment.production) ? 'Gabriela Lima' : null, {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      ccExp: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      cardCVV: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(3)]
      }),
      cardCPF: new FormControl((!environment.production) ? '16744247018' : null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(14)]
      }),
      isPrincipal: new FormControl(null, {
        updateOn: 'change'
      })
    });

    this.creditCardForm.get('cardCPF').setValue(this.maskPipe.transform(this.creditCardForm.get('cardCPF').value,'000.000.000-00'))


    const cardNum = new Cleave('#creditNum', {
      creditCard: true,
    });

    const cleave = new Cleave('#expDate', {
      date: true,
      datePattern: ['m', 'y']
    });
  }

  async handleScroll(event) {
    if (event && event.detail && event.detail.scrollTop) {
      const scrollEl = await event.target.getScrollElement();
      const scrollTop = event.detail.scrollTop;
      const scrollHeight = scrollEl.scrollHeight - scrollEl.clientHeight;

      if (this.isScrolled) {
        if (scrollTop < (scrollHeight - 10)) {
          this.isScrolled = false;
        }
      }

      if (!this.isScrolled) {
        if (scrollTop >= (scrollHeight - scrollTop)) {
          this.isScrolled = true;
        }
      }
    }
  }

  // Check for Card Number Input
  checkCardNumber(ev) {
    const value = ev.detail.value;

    if (!payform.validateCardNumber(value) && value !== '') {
      this.cardValidator.cardNumber = false;
    } else {
      this.cardValidator.cardNumber = true;
    }
  }

  // Check for Card Expiration Date Input
  checkCardExpDate(ev) {
    const expDate = ev.detail.value;

    if (expDate.length > 2) {
      const expValue = this.creditCardForm.value.ccExp;
      const split = expValue.split('/');
      if (!payform.validateCardExpiry(split[0], split[1])) {
        this.cardValidator.expDate = false;
      } else {
        this.cardValidator.expDate = true;
      }
    }
  }


  // check for card cvv input
  checkcvv(ev) {
    const cvv = ev.detail.value;
    const v = cvv.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const num = v.match(/\d{4}/g) || v.match(/\d{3}/g);
    const match = (num && num[0]) || '';
    const parts = [];

    for (let i = 0, len = match.length; i < len; i += 3) {
      parts.push(match.substring(i, i + 3));
    }

    if (parts.length > 1) {
      const cvv4Val = parts.join('');
      this.creditCardForm.controls.cardCVV.setValue(cvv4Val);
    } else {
      this.creditCardForm.controls.cardCVV.setValue(cvv);
    }

    const cvvForm = this.creditCardForm.value.cardCVV;

    if (!payform.validateCardCVC(cvvForm) && cvvForm !== '') {
      this.cardValidator.cVV = false;
    } else {
      this.cardValidator.cVV = true;
    }
  }


  // Onsubmit Card
  onSubmitCard(form: FormGroupDirective) {
    if (!this.creditCardForm.valid) {
      return;
    }

    const cardType = payform.parseCardType(this.creditCardForm.value.cardNumber);
    const expValue = this.creditCardForm.value.ccExp;
    const split = expValue.split('/');
    this.Cartao = {
      idCartao:null,
      nome: this.creditCardForm.value.cardHolderName,
      numeroCartao: this.creditCardForm.value.cardNumber,
      bandeiraCartaoEnum: cardType,
      tipoCartaoEnum: TipoCartaoEnum.CREDITO,
      mesExpiracao: split[0],
      anoExpiracao: split[1],
      codigoSeguranca: this.creditCardForm.value.cardCVV,
      cartaoPrincipal: this.creditCardForm.value.isPrincipal,
      cpf: this.creditCardForm.value.cardCPF
    }
    this.addCartao();
  }

  resetForm(form: FormGroup) {
    form.reset();
    this.creditCardForm.setValue({
      cardNumber: null,
      cardHolderName: null,
      ccExp: null,
      cardCVV: null,
      isPrincipal: null
    });
  }



  addCartao() {
    this.presentLoading();
    const Cartao = JSON.parse(JSON.stringify(this.Cartao));
    Cartao.cpf = Cartao.cpf?.replace(/\D/g, '');
    Cartao.anoExpiracao = Cartao.anoExpiracao.slice(-2);
    Cartao.numeroCartao = this.encriptar(Cartao.numeroCartao?.replace(/\s/g, ''));
    Cartao.bandeiraCartaoEnum = this.encriptar(Cartao.bandeiraCartaoEnum?.toUpperCase());
    Cartao.codigoSeguranca = this.encriptar(Cartao.codigoSeguranca);
    Cartao.cpf = this.encriptar(Cartao.cpf);
    Cartao.anoExpiracao = this.encriptar(Cartao.anoExpiracao);
    Cartao.mesExpiracao = this.encriptar(Cartao.mesExpiracao);
    Cartao.nome = this.encriptar(Cartao.nome);
    Cartao.tipoCartaoEnum = this.encriptar(Cartao.tipoCartaoEnum);
    this.ps.salvarCartao(Cartao).subscribe(res => {
      this.sharedService.show('Cartão cadastrado com sucesso','success');
      this.modalController.dismiss({ updated: true });
    }, err  => {
      console.log(err);
      this.modalController.dismiss({ updated: false });
    });
  }

  encriptar(string) {

    const encriptado = CryptoJS.AES.encrypt(
      string,
      environment
        .keys.metodoDePagamento
    ).toString();


    if (!environment.production) {
      console.log('encriptado: ', encriptado);
      console.log('decriptado: ', string);
    }
    return encriptado

  }


  fecharModal() {
    this.modalController.dismiss(null);
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Aguarde...',
      duration: 1000
    });
    await loading.present();
    const { role, data } = await loading.onDidDismiss();
  }

  atualizaMaskCpf(event){
      this.creditCardForm.get('cardCPF').setValue(this.maskPipe.transform(event.currentTarget.value ,'000.000.000-00'));
  }


  get cardCPF(){
    return this.creditCardForm.get('cardCPF');
  }

  get cardHolderName(){
    return this.creditCardForm.get('cardHolderName');
  }

  get cardNumber(){
    return this.creditCardForm.get('cardNumber');
  }

  get ccExp(){
    return this.creditCardForm.get('ccExp');
  }

  get cardCVV(){
    return this.creditCardForm.get('cardCVV');
  }

}