<section class="pt-2 pb-0 px-4 mb-2 mx-2">
  <div class="mt-2">
    <div class="row g-0">
      <div class="col">
        <h3 class="fw-light">Revise seu pedido</h3>
      </div>
      <div class="col-auto d-flex align-items-center mt-1 me-n3">
        <span class="d-flex align-items-center">
          <ion-chip color="danger">
            <ion-label class="text-small small">Comanda &nbsp;{{ atendimento?.idAtendimento }}</ion-label>
          </ion-chip>
        </span>
        <ion-button (click)="sair()" class="p-0 ms-2" color="light">
          <i class="fal fa-times" style="color:#000; font-size: 1.2em;"></i>
        </ion-button>
      </div>
    </div>
  </div>

  <div class="pl-3 my-4 pt-4" style="border-top: 1px dashed #e0e0e0; min-height: 2em">
    <div class="row" *ngFor="let c of carrinho?.listProdutoEntity">
      <div class="col">
        <b>{{ c?.quantidade }}x {{ c?.nomeProduto }}</b>
        <br />
        <div class="pl-3">
          <p class="fw-bold mb-2 text-dark" style="line-height: 1rem">
            <span class="small text-muted font-italic" *ngIf="c?.observacao"> ({{ c?.observacao }})<br /></span>
            <small class="text-muted" style="width: 140%">
              <span *ngFor="let adicional of c?.listPassosProdutoAgregadoEntity">
                <span *ngFor="let item of adicional?.listProdutoAgregadosEntity" [hidden]="!item.quantidade">
                  <span hidden> {{ adicional?.nomePassoProduto ? adicional?.nomePassoProduto + ':' : '' }}</span>
                  {{ item?.quantidade }}x {{ item?.nomeProduto }} - {{ item?.precoProduto * item?.quantidade | currency: 'BRL':'symbol-narrow':'1.0' }} <br /></span
              ></span>
            </small>
          </p>
        </div>
      </div>
      <div class="col-auto">{{ (c?.precoProdutoComAdicionais || c?.precoProduto) * c?.quantidade | currency: 'BRL':'symbol-narrow':'1.2-2' }}</div>
    </div>
  </div>

  <div style="border-top: 1px dashed #e0e0e0" class="pt-4 bg-white">
    <div class="row">
      <div class="col-6">
        <p class="fw-light mb-0">Total</p>
        <h1 class="fw-bold my-0">{{ carrinho?.valor | currency: 'BRL':'symbol-narrow':'1.2-2' }}</h1>
      </div>
      <div class="col-6">
        <ion-button (click)="confirmar()" expand="block" color="dark">
          <i class="fal fa-check-circle text-warning"></i> &nbsp;&nbsp;
          <span> Tudo certo </span>
        </ion-button>
      </div>
    </div>
    <div class="row">
      <div class="col-6">&nbsp;</div>
      <div class="col-6 text-center">
        <ion-button (click)="sair()" fill="clear" size="small" color="dark">
          <span> Editar carrinho </span>
        </ion-button>
      </div>
    </div>
  </div>
</section>
